import React from "react";
import PageLayout from "../Layout/Layout";
import EdenVouchers from "../components/EdenVouchers";
import AlmondVouchers from "../components/AlmondVouchers";


const UploadVouchers = () => {


  return (
    <>
     <PageLayout>
      <AlmondVouchers/>
      <EdenVouchers/>
      </PageLayout>
    </>
  );
};

export default UploadVouchers;
