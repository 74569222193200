import React from "react";

const Loader = () => {
  return (
    <>
      <>
        <div className="height">
          <div className="loader"></div>
        </div>
      </>
      <style>{`
      .height{
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .loader {
        border: 7px solid #000;
        border-radius: 50%;
        border-top: 7px solid grey;
        width: 50px;
        height: 50px;
        -webkit-animation: spin 2s linear infinite; 
        animation: spin 2s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
            `}</style>
    </>
  );
};

export default Loader;