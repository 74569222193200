import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { baseUrl } from "../constant";
import axios from "axios";
import Loader from "../../src/components/loader";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";

const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  const [organizationNameFilter, setOrganizationNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    { field: "id", headerName: "id", width: 50 },
    {
      field: "contact_person_name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "business_contact_number",
      headerName: "Business Contact",
      width: 150,
    },
    {
      field: "technical_contact_number",
      headerName: "Technical Contact",
      width: 150,
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      width: 200,
    },
    {
      field: "organization_pan_number",
      headerName: "Organization pan",
      width: 200,
    },

    {
      field: "website_link",
      headerName: "Website Link",
      width: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 250,
    },
  ];

  const getVendors = async () => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };
      const res = await axios.get(
        `${baseUrl}/gratification/get-all-vendors?page=${page}&size=${size}&organization_name=${organizationNameFilter}&email=${emailFilter}`,
        configHeaders
      );
      console.log("🚀 ~ file: vendors.js:72 ~ getVendors ~ res:", res);

      const vendorsData = await res?.data?.data;

      console.log(
        "🚀 ~ file: vendors.js:67 ~ getVendors ~ vendorsData:",
        vendorsData
      );
      let result = [];
      vendorsData?.data?.map((dt, i) => {
        result.push({
          id: (page - 1) * size + i + 1,
          business_contact_number: dt?.business_contact_number,
          contact_person_name: dt?.contact_person_name,
          email: dt?.email,
          organization_name: dt?.organization_name,
          organization_pan_number: dt?.organization_pan_number,
          phone_number: dt?.phone_number,
          technical_contact_number: dt?.technical_contact_number,
          website_link: dt.website_link,
          createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
          updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
        });
      });
      // let result = vendorsData?.data
      //   .filter(
      //     (dt) =>
      //       dt.organization_name
      //         .toLowerCase()
      //         .includes(searchTerm.toLowerCase()) ||
      //       dt.email.toLowerCase().includes(searchTerm.toLowerCase())
      //   )
      //   .map((dt, i) => ({
      //     ind: page * size + i,
      //     id: i + 1,
      //     business_contact_number: dt?.business_contact_number,
      //     contact_person_name: dt?.contact_person_name,
      //     email: dt?.email,
      //     organization_name: dt?.organization_name,
      //     organization_pan_number: dt?.organization_pan_number,
      //     phone_number: dt?.phone_number,
      //     technical_contact_number: dt?.technical_contact_number,
      //     website_link: dt.website_link,
      //     createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
      //     updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
      //   }));
      setVendors(result);
      setPagination(vendorsData?.totaCount);
      setLoading(false);
      // console.log("Pagination: ", pagination);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleSearch = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value); 
    if (value.includes("@")) {
      setEmailFilter(value);
      setOrganizationNameFilter(""); 
    } else {
      setOrganizationNameFilter(value);
      setEmailFilter(""); 
    }
    setPage(1);
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setOrganizationNameFilter("");
    setEmailFilter("");
  };

  useEffect(() => {
    getVendors();
    // console.log("Pagination", pagination);
  }, [page, size, organizationNameFilter, emailFilter]);

  return (
    <>
      <PageLayout>
        <Card className="mt-4" style={{ width: "100%" }}>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              style={{ fontWeight: 600, fontFamily: "poppins" }}
            >
              Clients
              <TextField
                label="Search Organization Name or Email"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  float: "right",
                  marginTop: 0,
                }}
              />
              {searchTerm && (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={handleClearFilter}
                  style={{
                    float: "right",
                    marginRight: "10px",
                    marginTop: "10px",
                    // backgroundColor: "green",
                    fontWeight: "600",
                  }}
                >
                  Clear Filter
                </Button>
              )}
              {/*  */}
            </Typography>
            <div style={{ marginBottom: "20px" }}>
              <Typography variant="body1" color="textSecondary">
                Total Count: {pagination}
              </Typography>
            </div>
            <div style={{ height: "auto" }}>
              {loading ? (
                <Loader />
              ) : vendors?.length === 0 ? (
                <div className="result-not-found">Data Not Found</div>
              ) : (
                <DataGrid
                  rows={vendors}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  pageSize={size}
                  rowCount={pagination}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPage(newPage + 1);
                  }}
                  onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                  autoHeight
                  {...vendors}
                />
              )}
            </div>
          </CardContent>
        </Card>
        <style>{`

      .react-toggle--checked .react-toggle-track {
        background-color: grey !important;
      }
      .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
        margin: auto;
      }
      .css-levciy-MuiTablePagination-displayedRows{
        margin: auto;
      }
      .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
        margin: 0
      }
      .css-t89xny-MuiDataGrid-columnHeaderTitle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: var(--unstable_DataGrid-headWeight);
        font-size: 15px;
        font-weight: bold;
      }


    .css-1pnmrwp-MuiTypography-root {
        margin: 0;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: green;
    }
    .result-not-found {
      text-align: center;
      margin-top: 20px;
      color: red;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      font: inherit;
      letter-spacing: inherit;
      color: currentColor;
      padding: 4px 0 5px;
      border: 0;
      box-sizing: content-box;
      background: none;
      height: 1.4375em;
      margin: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      min-width: 0;
      width: 100%;
      -webkit-animation-name: mui-auto-fill-cancel;
      animation-name: mui-auto-fill-cancel;
      -webkit-animation-duration: 10ms;
      animation-duration: 10ms;
      padding: 16.5px 46px;
  }
    
  `}</style>
      </PageLayout>
    </>
  );
};

export default Vendors;
