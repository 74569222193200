import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import PageLayout from "../Layout/Layout";
import { baseUrl } from "../constant";
import { useNavigate } from "react-router-dom";

const CreateRole = () => {

  const token = localStorage.getItem("token")
  const navigate = useNavigate();

  const handleSubmit = async (values, { resetForm }) => {
    try {
        const response = await axios.post(`${baseUrl}/gratification/create-role`, {
            role: values.role,
        },  {
            headers: {
              Authorization: `Bearer ${token}`
            },
        });
        if (response && response.data && response.data.statusCode === 200) {
            Swal.fire({
              title: "Success",
              text: "Role Created Successfully",
              icon: "success",
            });
            navigate("/dashboard");
            resetForm();
          } else if (response && response.data && response.data.statusCode === 409) {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
          } else if (response && response.data && response.data.status === false) {
            Swal.fire({
              title: "Error",
              text: response.data.message,
              icon: "error",
            });
          } else {
            console.error('Failed to create role');
            Swal.fire({
              title: "Error",
              text: "Failed to create role",
              icon: "error",
            });
          }
        } catch (error) {
          console.error('Error occurred:', error);
          Swal.fire({
            title: "Error",
            text:  error?.response?.data?.message,
            icon: "error",
          });
        }
};
  
  

const validationSchema = Yup.object().shape({
    role: Yup.string().trim().required('Role name is required'),
});
  

  return (
    <>
    <PageLayout>
      <div
        className="container"
        style={{
          backgroundColor: "",
          minHeight: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="registration-container">
          <div className="d-flex">
            <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
              <div className="h1 fw-bold mb-0 almond-logo">
                <img
                  className="img-fluid login-page-logo"
                  src="/images/almond-logo.png"
                />
              </div>
            </div>
            <h2 className="fs-1 mb-3 pb-3 text-center"></h2>
          </div>
          <Formik
                    initialValues={{
                        role: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ errors, touched }) => (
                        <Form>
                            <div className="form-group">
                                <label style={{ float: "left" }}>Role Name</label>
                                <Field
                                    type="text"
                                    name="role"
                                    className="form-control"
                                    placeholder="Enter role name"
                                />
                                <ErrorMessage name="role" component="div" className="error" />
                            </div>

                            {/* Add more fields and their error handling components */}

                            <div className="form-group text-center">
                                <button type="submit" className="btn-lg buttonlogin">
                                    Register
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>

        </div>
      </div>
      <style>
        {`
        .registration-container {
          width: 70%;
          padding: 50px;
          border-radius: 8px;
          background-color: #f7f7f7;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .form-group {
          margin: 10px;
          text-align: center;
          width: 100%;
        }
        .error {
          color: #ff0000;
          font-size: 14px;
        }
        .buttonlogin, .buttonlogin:hover{
          background:#ea8523;
          color:#fff;
          border:none;
          outline:none;
          font-size:22px;
          margin-top: 20px
        }

        .registration-container input{
          font-size:18px;
          width:100%;
        }
        .registration-container input:active,.registration-container input:hover, .registration-container input:focus, .registration-container input:focus-visible{
          border:none !important;
          outline:none !important;
        }
        .registration-container label{
          font-size:18px;
          font-weight:500;
        }
        .registration-container input::placeholder{
          font-size:16px;
  
        }
        .almond-logo{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-page-logo{
          max-width:300px !important;
        }
        .login-page-logo{
          width: 80%;
        }
        

      `}
      </style>
      </PageLayout>
    </>
  );
};

export default CreateRole;

