import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from "../constant";
import PageLayout from "../Layout/Layout";
import { useVendorId } from "../VendorIdContext";

export default function ClientInformation() {
  const [menuData, setMenuData] = useState([]);
  const [nameProvider, setNameProvider] = useState([]);
  // const { vendorId } = useVendorId();
  const vendorId = localStorage.getItem("vendor_id");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Bearer token not found in localStorage");
      return;
    }

    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .get(`${baseUrl}/admin/vendor-service-lists?vendor_id=${vendorId}`, {
        headers,
      })
      .then((response) => {
        const data = response.data.data;
        console.log("datata", data);
        setMenuData(data);
      })
      .catch((error) => {
        console.error("Error fetching menu data:", error);
      });
  }, [vendorId]);

  useEffect(() => {
    const handleBackButton = () => {
      window.history.pushState(null, null, window.location.pathname);
      window.history.forward();
      window.location.replace("/dashboard");
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);


  const getdata = () => {
    menuData.map((data) => {
      nameProvider.push({
        name: data.service_id.name,
        serviceProvider: data.service_id.service_provider,
      });
    });

    console.log("nameProvider", nameProvider);
    return nameProvider;
  }

  getdata();
  const getDistinctObjects = (array) => {
    return array.filter((object, index) => {
      const firstIndex = array.findIndex(
        (obj) =>
          obj.name === object.name && obj.serviceProvider === object.serviceProvider
      );
      return index === firstIndex;
    });
  };
const arr = getDistinctObjects(nameProvider);
  console.log("disticenarra", arr )

   
 

  return (
    <PageLayout>
      <div className="card-container">
        <h3 style={{ padding: 15, color: "#95500c" }}>Client Information</h3>
        {/* {menuData.map((item, index) => ( */}
          <div
            // key={index}
            className="card"
            style={{
              padding: 30,
            }}
          >
            <p>
              {" "}
              <strong>Name:</strong> &nbsp;{" "}
              {/* {item?.vendor_id?.contact_person_name} */}
              {menuData[0]?.vendor_id?.contact_person_name}
            </p>
            <p>
              {" "}
              <strong>Organization Name:</strong> &nbsp;{" "}
              {menuData[0]?.vendor_id?.organization_name}
            </p>
            <p>
              {" "}
              <strong>PAN Number:</strong> &nbsp;{" "}
              {menuData[0]?.vendor_id?.organization_pan_number}
            </p>
            <p>
              {" "}
              <strong>Phone Number: </strong> &nbsp;
              {menuData[0]?.vendor_id?.phone_number}
            </p>
            <p>
              {" "}
              <strong>Technical Person Number:</strong> &nbsp;
              {menuData[0]?.vendor_id?.technical_contact_number}
            </p>
            <p>
              {" "}
              <strong>Business Person Number:</strong> &nbsp;
              {menuData[0]?.vendor_id?.business_contact_number}
            </p>
            <p>
              {" "}
              <strong>Email: </strong> &nbsp;{menuData[0]?.vendor_id?.email}
            </p>
            <p>
              <strong>Service Name: </strong>&nbsp;
              {arr.map((data)=>{
                return(
                  <>{data.name}, </>
                )
              })}
              {/* {item.service_id?.name.replace(/_/g, " ").toUpperCase()} */}
            </p>

            <p>
              <strong>Service Provider: </strong>&nbsp;
              {arr.map((data)=>{
                return(
                  <>{data.serviceProvider}, </>
                )
              })}
              {/* {item?.service_id?.service_provider
                .replace(/_/g, " ")
                .toUpperCase()} */}
            </p>
          </div>
        {/* ))} */}
      </div>
    </PageLayout>
  );
}
