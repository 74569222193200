import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { baseUrl } from "../constant";
import axios from "axios";

const SideBar = () => {
  const [menuData, setMenuData] = useState([]);
  const [servicesMenuOpen, setServicesMenuOpen] = useState(false);
  const [vendorMenuOpen, setVendorMenuOpen] = useState(false);
  const [othersMenuOpen, setOthersMenuOpen] = useState(false);
  const [productsMenuOpen, setProductsMenuOpen] = useState(false);
  const [brandsMenuOpen, setBrandsMenuOpen] = useState(false);
  const [categoriesMenuOpen, setCategoriesMenuOpen] = useState(false);
  const [settingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const [role, setRole] = useState("");

  const getRole = () => {
    const roles = typeof window != "undefined" && localStorage.getItem("role");
    console.log("role", roles);
    setRole(roles);
  };

  const vendorId = localStorage.getItem("vendor_id");
  const location = useLocation();

  const organization_name = localStorage.getItem("organization_name");

  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("Bearer token not found in localStorage");
      return;
    }

    if (role === "client") {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get(`${baseUrl}/admin/vendor-service-lists?vendor_id=${vendorId}`, {
          headers,
        })
        .then((response) => {
          // console.log("🚀 ~ file: SideBar.js:47 ~ .then ~ response:@####", response)
          const data = response.data.data;
          console.log("Services dataaaa", data);
          const uniqueServiceNames = new Set();

          data.forEach((item) => {
            console.log(item);
            if (item.service_id) {
              uniqueServiceNames.add(item.service_id.name);
            }
          });
          const uniqueServiceNamesArray = Array.from(uniqueServiceNames);

          setMenuData(uniqueServiceNamesArray);
        })
        .catch((error) => {
          console.error("Error fetching menu data:", error);
        });
    }
  }, [role, vendorId]);

  useEffect(() => {
    console.log(menuData);
  }, [menuData]);

  useEffect(() => {
    if (
      location.pathname.includes("/vendors") ||
      location.pathname.includes("/allTransactions")
    ) {
      setVendorMenuOpen(true);
    } else {
      setVendorMenuOpen(false);
    }

    if (
      location.pathname.includes("/AdminProduct") ||
      location.pathname.includes("/CreateVendorProduct") ||
      location.pathname.includes("/updateProduct")
    ) {
      setProductsMenuOpen(true);
    } else {
      setProductsMenuOpen(false);
    }

    if (
      location.pathname.includes("/Brands") ||
      location.pathname.includes("/CreateVendorBrands") ||
      location.pathname.includes("/updateBrands")
    ) {
      setBrandsMenuOpen(true);
    } else {
      setBrandsMenuOpen(false);
    }

    if (
      location.pathname.includes("/Categories") ||
      location.pathname.includes("/CreateVendorCategory") ||
      location.pathname.includes("/updateCategories")
    ) {
      setCategoriesMenuOpen(true);
    } else {
      setCategoriesMenuOpen(false);
    }

    if (
      location.pathname.includes("/upi") ||
      location.pathname.includes("/mobile_recharge") ||
      location.pathname.includes("/insurance") ||
      location.pathname.includes("/vouchers") ||
      location.pathname.includes("/coupon_card")
    ) {
      setServicesMenuOpen(true);
    } else {
      setServicesMenuOpen(false);
    }

    if (
      location.pathname.includes("/services") ||
      location.pathname.includes("/transactions") ||
      location.pathname.includes("/uploadVouchers") ||
      location.pathname.includes("/CreateAdmin")
    ) {
      setOthersMenuOpen(true);
    } else {
      setOthersMenuOpen(false);
    }

    if (
      location.pathname.includes("/users") ||
      location.pathname.includes("/profile")
    ) {
      setSettingsMenuOpen(true);
    } else {
      setSettingsMenuOpen(false);
    }
  }, [location.pathname]);

  return (
    <>
      <div className={`mc-sidebar`}>
        <div className="mc-sidebar-wrapper">
          <div className="sidebar_bg">
            {/* <div className="profile_info">
            <img
              src="/images/avtar.png"
              alt="logo-image "
            />
            <p>{localStorage.getItem('organization_name')} <span>{localStorage.getItem('email')}</span></p>
            </div> */}
            <div className="profile_info">
              <img src="/images/avtar.png" alt="logo-image" />
              <div className="profile-text">
                {role === "super_admin" && (
                  <p className="organization-name">
                    {role.charAt(0).toUpperCase() +
                      role.slice(1, 6).toLowerCase().replace("_", " ") +
                      role.charAt(6).toUpperCase() +
                      role.slice(7).toLowerCase()}
                  </p>
                )}

                {role === "client" && (
                  <p className="organization-name">{organization_name}</p>
                )}
                {/* <p className="email">{email}</p> */}
              </div>
            </div>
          </div>
          <ul>
            <li>
              <NavLink
                to="/dashboard"
                style={({ isActive }) => ({
                  color: isActive ? "ActiveColor" : "",
                })}
              >
                <i className="fa-solid fa-house "></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            {/* here is the sub menu code */}

            {role === "super_admin" && (
              <li onClick={() => setVendorMenuOpen(!vendorMenuOpen)}>
                <a className={vendorMenuOpen ? "ActiveBtn" : ""}>
                  <i class="fa fa-users"></i>
                  <span>Clients</span>
                  <p className="dropdown_btn fa-solid fa-angle-right"></p>
                </a>
              </li>
            )}
            {vendorMenuOpen && (
              <ul className="toggle_div">
                {role == "super_admin" && (
                  <li>
                    <NavLink
                      to="/vendors"
                      style={({ isActive }) => ({
                        color: isActive ? "ActiveColor" : "",
                      })}
                    >
                      <i class="fa-solid fa-user-secret"></i>
                      <span>All Clients</span>
                    </NavLink>
                  </li>
                )}
                {role == "super_admin" && (
                  <li>
                    <NavLink
                      to="/allTransactions"
                      style={({ isActive }) => ({
                        color: isActive ? "ActiveColor" : "",
                      })}
                    >
                      <i class="fa-solid fa-money-bill-1"></i>
                      <span>All Transactions</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            )}

            {/* here is the sub menu code */}

            {role === "client" && (
              <li onClick={() => setServicesMenuOpen(!servicesMenuOpen)}>
                <a className={servicesMenuOpen ? "ActiveBtn" : ""}>
                  <i class="fa-solid fa-gears"></i>
                  <span>Services</span>
                  <p className="dropdown_btn fa-solid fa-angle-right"></p>
                </a>
              </li>
            )}
            {role === "client" && servicesMenuOpen && (
              <ul className="toggle_div">
                {menuData &&
                  menuData.map((item) => {
                    return (
                      <li key={item}>
                        <NavLink
                          to={`/${item}`}
                          style={({ isActive }) => ({
                            color: isActive ? "ActiveColor" : "",
                          })}
                        >
                          <i class="fa-solid fa-circle fa-xs"></i>
                          <span>{item}</span>
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            )}

            {/* here is the sub menu code */}

            {/* here is the sub menu code */}

            {/* others menu start  */}
            <li onClick={() => setOthersMenuOpen(!othersMenuOpen)}>
              <a className={othersMenuOpen ? "ActiveBtn" : ""}>
                <i className="fa fa-cog "></i>
                <span>Others</span>
                <p className="dropdown_btn fa-solid fa-angle-right"></p>
              </a>
            </li>
            {othersMenuOpen && (
              <ul className="toggle_div">
                <li>
                  <NavLink
                    to="/services"
                    style={({ isActive }) => ({
                      color: isActive ? "ActiveColor" : "",
                    })}
                  >
                    <i className="fa fa-credit-card "></i>
                    <span>All Services</span>
                  </NavLink>
                </li>
                {role === "client" && (
                  <li>
                    <NavLink
                      to="/transactions"
                      style={({ isActive }) => ({
                        color: isActive ? "ActiveColor" : "",
                      })}
                    >
                      <i class="fa-solid fa-money-bill-1"></i>
                      <span>All Transactions</span>
                    </NavLink>
                  </li>
                )}

                {role === "super_admin" && (
                  <li>
                    <NavLink
                      to="/uploadVouchers"
                      style={({ isActive }) => ({
                        color: isActive ? "ActiveColor" : "",
                      })}
                    >
                      <i className="fa fa-user "></i>
                      <span>Upload Vouchers</span>
                    </NavLink>
                  </li>
                )}

                {role === "super_admin" && (
                  <li>
                    <NavLink
                      to="/CreateAdmin"
                      style={({ isActive }) => ({
                        color: isActive ? "ActiveColor" : "",
                      })}
                    >
                      <i className="fa fa-user "></i>
                      <span>Create Admin</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            )}
            {/* others menu end */}
            <li onClick={() => setSettingsMenuOpen(!settingsMenuOpen)}>
              <a className={settingsMenuOpen ? "ActiveBtn" : ""}>
                <i className="fa fa-cog "></i>
                <span>Setting</span>
                <p className="dropdown_btn fa-solid fa-angle-right"></p>
              </a>
            </li>
            {settingsMenuOpen && (
              <ul className="toggle_div">
                <li>
                  <NavLink
                    to="/users"
                    style={({ isActive }) => ({
                      color: isActive ? "ActiveColor" : "",
                    })}
                  >
                    <i className="fa fa-user "></i>
                    <span>Users</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/profile"
                    style={({ isActive }) => ({
                      color: isActive ? "ActiveColor" : "",
                    })}
                  >
                    <i className="fa fa-user "></i>
                    <span>Profile</span>
                  </NavLink>
                </li>
              </ul>
            )}
            {role === "super_admin" && (
              <li>
                <NavLink
                  to="/registration"
                  style={({ isActive }) => ({
                    color: isActive ? "ActiveColor" : "",
                  })}
                >
                  <i className="fa fa-user "></i>
                  <span>Register Client</span>
                </NavLink>
              </li>
            )}

            {role === "super_admin" && (
              <li>
                <NavLink
                  to="/CreateRole"
                  style={({ isActive }) => ({
                    color: isActive ? "ActiveColor" : "",
                  })}
                >
                  <i className="fa fa-user "></i>
                  <span>Register Role</span>
                </NavLink>
              </li>
            )}

            {role === "super_admin" && (
              <li>
                <NavLink
                  to="/CreateBrand"
                  style={({ isActive }) => ({
                    color: isActive ? "ActiveColor" : "",
                  })}
                >
                  <i className="fa fa-user "></i>
                  <span>Register Brand</span>
                </NavLink>
              </li>
            )}
            {role === "super_admin" && (
              <li>
                <NavLink
                  to="/assignServices"
                  style={({ isActive }) => ({
                    color: isActive ? "ActiveColor" : "",
                  })}
                >
                  <i className="fa fa-user "></i>
                  <span>Assign Services</span>
                </NavLink>
              </li>
            )}
            {role === "super_admin" && (
              <li>
                <NavLink
                  to="/update-awb"
                  style={({ isActive }) => ({
                    color: isActive ? "ActiveColor" : "",
                  })}
                >
                  <i className="fa fa-user "></i>
                  <span>Update AWB</span>
                </NavLink>
              </li>
            )}
          </ul>
        </div>
      </div>
      <style>
        {`
    .profile-text {
      display: flex;
      flex-direction: column;
    }
    
    .organization-name, .email {
      margin: 0;
      white-space: pre-wrap;
    }
    .mc-sidebar {
      min-height: unset;
      height: calc(100vh - 45px);
      overflow-y: auto;
      padding-bottom: 20px;
    }
    
    `}
      </style>
    </>
  );
};

export default SideBar;
