import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useVendorId } from "../VendorIdContext";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../constant";
import PageLayout from "../Layout/Layout";
// import {
//   FaUser,
//   FaBuilding,
//   FaIdCard,
//   FaGlobe,
//   FaPhone,
//   FaEnvelope,
//   FaLock,
// } from "react-icons/fa";
import {
  FaUser,
  FaBuilding,
  FaIdCard,
  FaGlobe,
  FaPhone,
  FaEnvelope,
  FaLock,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";

const Registration = () => {
  // const [vendorId, setVendorId] = useState(null);
  // const { setAndSaveVendorId } = useVendorId();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const initialValues = {
    contact_person_name: "",
    organization_name: "",
    organization_pan_number: "",
    website_link: "",
    phone_number: "",
    technical_contact_number: "",
    business_contact_number: "",
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    contact_person_name: Yup.string().trim().required("Required"),
    organization_name: Yup.string().trim().required("Required"),
    organization_pan_number: Yup.string()
      .matches(
        /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        "Invalid PAN format"
      )
      .required("Required"),
    website_link: Yup.string().url("Invalid URL").required("Required"),
    phone_number: Yup.string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Phone number must start with 6, 7, 8, or 9 and be 10 digits long",
      excludeEmptyString: true,
    })
    .required("Required"),
    technical_contact_number: Yup.string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Technical Contact number must start with 6, 7, 8, or 9 and be 10 digits long",
      excludeEmptyString: true,
    })
    .required("Required"),
    business_contact_number: Yup.string()
    .matches(/^[6-9]\d{9}$/, {
      message: "Business Contact number must start with 6, 7, 8, or 9 and be 10 digits long",
      excludeEmptyString: true,
    })
    .required("Required"),
    email: Yup.string().email("Invalid email format").required("Required"),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
        "Password must be at least 8 characters long, start with a capital letter, and contain at least one special character"
      )
      .required("Required"),
  });
  

  // const onSubmit = (values) => {
  //   console.log(values);
  // };

  const onSubmit = (values) => {
    const requestData = {
      contact_person_name: values.contact_person_name,
      organization_name: values.organization_name,
      organization_pan_number: values.organization_pan_number,
      website_link: values.website_link,
      phone_number: values.phone_number.toString(),
      technical_contact_number: values.technical_contact_number.toString(),
      business_contact_number: values.business_contact_number.toString(),
      email: values.email,
      password: values.password,
    };

    const token = localStorage.getItem("token");

    console.log("requestData", requestData);
    axios
      .post(`${baseUrl}/gratification/create-vendors`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(
          "🚀 ~ file: registration.js:57 ~ .then ~ response:",
          response
        );
        if (response.status === 200) {
          const vendorId = response.data.data.vendor_id;
          localStorage.setItem("vendor_id", vendorId)
          console.log("Received Vendor ID:", vendorId);

          Swal.fire({
            title: "Success",
            text: response.data.message,
            icon: "success",
          });
          // setAndSaveVendorId(vendorId);
          document.querySelector("form").reset();
          navigate("/registerForm");
        } else {
          throw new Error("Registration failed");
        }
      })
      .catch((error) => {
        console.log("hhd", error)
        Swal.fire({
          title: "Error",
          text: error?.response?.data.message,
          icon: "error",
        });
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <PageLayout>
        <div
          className="container"
          style={{
            backgroundColor: "",
            // minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="registration-container">
            <div className="d-flex mt-3">
              <h2 className="fs-2 mb-3 pb-3" style={{ color: "#1e2c42" }}>
                Register Client
              </h2>
            </div>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form onKeyPress={handleKeyPress}>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <div className="label">
                      <FaUser /> <label>Contact Person Name</label>
                      </div>
                      <Field
                        type="text"
                        name="contact_person_name"
                        className="form-control"
                        placeholder="Type Contact Person Name"
                      />
                      <ErrorMessage
                        name="contact_person_name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                    <div className="label">
                      <FaBuilding /> <label>Organization Name</label>
                      </div>
                      <Field
                        type="text"
                        name="organization_name"
                        className="form-control"
                        placeholder="Type Organization Name"
                      />
                      <ErrorMessage
                        name="organization_name"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                    <div className="label">
                      <FaIdCard /> <label>Organization PAN Number</label>
                      </div>
                      <Field
                        type="text"
                        name="organization_pan_number"
                        className="form-control"
                        placeholder="Type organization PAN Number"
                      />
                      <ErrorMessage
                        name="organization_pan_number"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                    <div className="label">
                      <FaLock /> <label>Password</label>
                      </div>
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Type Password"
                      />
                      {showPassword ? (
                        <FaEyeSlash
                          style={{
                            position: "absolute",
                            top: "15%",
                            right: "20px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <FaEye
                          style={{
                            position: "absolute",
                            top: "15%",
                            right: "20px",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={() => setShowPassword(true)}
                        />
                      )}
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                    <div className="label">
                      <FaPhone /> <label>Phone Number</label>
                      </div>
                      <Field
                        type="number"
                        name="phone_number"
                        className="form-control"
                        placeholder="Type Phone Number"
                      />
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                    <div className="label">
                      <FaPhone /> <label>Technical Person Number</label>
                      </div>
                      <Field
                        type="number"
                        name="technical_contact_number"
                        className="form-control"
                        placeholder="Type Technical Contact Number"
                      />
                      <ErrorMessage
                        name="technical_contact_number"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                    <div className="label">
                      <FaPhone /> <label>Business Person Number</label>
                      </div>
                      <Field
                        type="number"
                        name="business_contact_number"
                        className="form-control"
                        placeholder="Type Business Contact Number"
                      />
                      <ErrorMessage
                        name="business_contact_number"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                    <div className="label">
                      <FaEnvelope /> <label>Email</label>
                      </div>
                      <Field
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Type Your Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error"
                      />
                    </div>
                    <div className="form-group">
                    <div className="label">
                      <FaGlobe /> <label>Website Link</label>
                      </div>
                      <Field
                        type="text"
                        name="website_link"
                        className="form-control"
                        placeholder="Type Website Link"
                      />
                      <ErrorMessage
                        name="website_link"
                        component="div"
                        className="error"
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group text-center">
                  <button type="submit" className="btn-lg  buttonlogin">
                    Submit
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
        <style>
          {`
        .registration-container {
          padding: 20px;
          // border-radius: 8px;
          // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .form-group {
          // margin: 10px;
          text-align: center;
          width: 100%;
          margin-bottom: 1.5rem;
        }
        .error {
          color: #ff0000;
          font-size: 14px;
        }
        .buttonlogin, .buttonlogin:hover{
          background:#ea8523;
          color:#fff;
          border:none;
          outline:none;
          font-size:22px;
          // margin-top: 3px;
        }
        .form-group label{
          color: #ea8523;
        }
        .col-md-4{
          min-height: 330px
        }
        .label{
          float: left;
        }

        .registration-container input{
          font-size:18px;
          width:100%;
        }
        
        // .registration-container input:active,.registration-container input:hover, .registration-container input:focus, .registration-container input:focus-visible{
        //   border:none !important;
        //   outline:none !important;
        // }
        .registration-container label{
          font-size:15px;
          font-weight:500;
        }
        .registration-container input::placeholder{
          font-size:15px;
  
        }
        .almond-logo{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-page-logo{
          max-width:300px !important;
        }
        .login-page-logo{
          width: 80%;
        }

        
        

      `}
        </style>
      </PageLayout>
    </>
  );
};

export default Registration;
