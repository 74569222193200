import Navbar from "../components/Navbar";
import SideBar from "../components/SideBar";

export default function PageLayout({children}) {
  return (
    <>
      <Navbar />
      <SideBar/>
      <div className="mc-main-container">{children}</div>
    </>
  );
}
