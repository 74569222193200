import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import Swal from "sweetalert2";
import PageLayout from "../Layout/Layout";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { baseUrl } from "../constant";
import { useState, useRef } from "react";

const validationSchema = Yup.object().shape({
  file: Yup.mixed().required("File is required"),
});

const initialValues = {
  file: null,
};

const EdenVouchers = () => {
  const [edenFile, setEdenFile] = useState("");
  const fileInputRef = useRef(null);
  // const [clickedButton, setClickedButton] = useState("");

  const handleSubmit = async (values, { setSubmitting, resetForm  }) => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const formData = new FormData();

      if (!values.file) {
        console.error(
          "File is null. Make sure the input field is named 'file'."
        );
        return;
      }

      formData.append("file", values.file);

      const url = `${baseUrl}/gratification/edenred-upload-vouchers`;

      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${tokenFromLocalStorage}`,
        },
      });

      if (response.status === 200 && response.data.status) {
        Swal.fire({
          icon: "success",
          title: "Uploaded Successfully",
          text: response.data.message || "Vouchers Uploaded Successfully",
        })
      } else {
        throw new Error("Upload failed for first API");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error?.response?.data?.message || "Something went wrong.",
      });
      console.log("Error:", error);
    } finally {
      setSubmitting(false);
      resetForm();
      setEdenFile(""); // Reset the file state
      if (fileInputRef.current) {
        fileInputRef.current.value = null; // Clear the file input value
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, handleSubmit, resetForm, values }) => (
          <Form onSubmit={handleSubmit}>
              <Card className="mt-4" style={{ width: "100%" }}>
                <CardContent className="card-content">
                  <Typography
                    variant="h6"
                    component="div"
                    className="Text-field"
                  >
                    Upload Vouchers (for Edenred)
                  </Typography>

                  <div className="file-text">
                    <label htmlFor="file" className="text">
                      Upload File:
                    </label>
                    <input
                    ref={fileInputRef}
                      style={{ marginBottom: 10, fontSize: 15 }}
                      type="file"
                      id="file"
                      name="file"
                      onChange={(event) => {
                        if (
                          event.currentTarget.files[0]?.type ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        ) {
                          setFieldValue("file", event.currentTarget.files[0]);
                          setEdenFile(Date.now());
                        } else {
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: "Only Excel(.xlsx) files are allowed",
                          });
                          setEdenFile("");
                        }
                      }}
                    />
                    <ErrorMessage
                      name="file"
                      component="div"
                      className="error"
                    />
                  </div>
                {
                  <button
                    type="submit"
                    className="btn-btn"
                    disabled={!edenFile}
                  >
                    Submit
                  </button>
                }
                </CardContent>
              </Card>
          </Form>
        )}
      </Formik>

      <style>
        {`
          .error {
            color: red;
            font-size: 14px;
          }
          .Text-field{
            color: #95500c;
            font-size: 20px;
            font-weight: 600
          }
          .file-text{
            margin: 10px 0px;
            margin-bottom: 10px;
          }
          .text{
            margin-right: 12px;
            font-size: 15px;
            font-weight: 500;
          }
          .btn-btn{
            background-color: #ea8523;
            // background-color: green;
            color: white;
            padding: 5px 14px;
            border-radius: 10px;
            border: none;
          }

        `}
      </style>
    </>
  );
};

export default EdenVouchers;
