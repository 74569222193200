import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios, { AxiosError } from "axios";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import { baseUrl } from "../constant";
import PageLayout from "../Layout/Layout";
import { useNavigate } from "react-router-dom";

const CreateVendorBrands = () => {
  const [vendorDetails, setVendorDetails] = useState(null);
  const [error, setError] = useState(null);
  const [brands, setBrands] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectionModel, setSelectionModel] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.post(
        `${baseUrl}/gratification/get-vendor-details`,
        {
          email: values.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const id = response.data.data.data._id; // Extracting _id from the response
      setVendorDetails(response.data);

      // Save _id as vendor_id
      localStorage.setItem("vendor_id", id);

      // Call fetch-brands API
      const configHeaders = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const brandsresponse = await axios.get(
        `${baseUrl}/gratification/fetch-brands`,
        configHeaders
      );
      const brands = brandsresponse.data.data;
      console.log("brands", brands);
      let result = [];
      brands?.map((dt, i) => {
        result.push({
          _id: dt?._id,
          brand_name: dt?.brand_name,
          brand_code: dt?.brand_code,
          status: dt?.status,
        });
      });
      setBrands(result);
      //   setLoading(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleCreateBrands = async () => {
    const token = localStorage.getItem("token");
    const vendor_id = localStorage.getItem("vendor_id");

    try {
      const response = await axios.post(
        `${baseUrl}/admin/create-vendor-brand`,
        {
          vendor_id,
          brand_id: [...selectionModel],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
        Swal.fire({
          icon: "success",
          title: "Brands created successfully!",
          text: response.data.message,
        });
        navigate("/Brands");
        setSelectionModel([]); // Clear selection
        setVendorDetails(null); // Reset vendor details
        setBrands([]); // Clear brands list
        setError(null); // Reset error
    } catch (error) {
      // Handle error
      console.log(error.message);
      if (error.response && error.response.status === 400) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Select Brands",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.message
        });
      }
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email must be in accurate format"
      )
      .required("Email is required"),
  });

  const columns = [
    { field: "_id", headerName: "ID", width: 300 },
    { field: "brand_name", headerName: "Brand Name", width: 200 },
    { field: "brand_code", headerName: "Brand Code", width: 400 },
    // Add more columns as needed
  ];

  return (
    <PageLayout>
      <div
        className="container"
        style={{
          backgroundColor: "",
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="registration-container">
          <div className="d-flex">
            <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
              <div className="h1 fw-bold mb-0 almond-logo">
                <img
                  className="img-fluid login-page-logo"
                  src="/images/almond-logo.png"
                />
              </div>
            </div>
            <h2 className="fs-1 mb-3 pb-3 text-center"></h2>
          </div>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched }) => (
              <Form style={{ width: "100%" }}>
                <div className="form-group">
                  <label style={{ float: "left" }}>Email</label>
                  <Field
                    type="text"
                    name="email"
                    className="form-control"
                    placeholder="Enter Email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error"
                  />
                </div>

                <div className="form-group text-center">
                  <button
                    type="submit"
                    className="btn-lg buttonlogin"
                    style={{ fontSize: "18px" }}
                  >
                    Get Brands
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          {brands.length > 0 && (
            <div className="container">
              <h2>Brands</h2>
              <div style={{ height: 400, width: "100%" }}>
                <DataGrid
                  rows={brands}
                  columns={columns}
                  pageSize={rowsPerPage}
                  pagination
                  autoHeight
                  rowsPerPageOptions={[5, 10, 20, 25]}
                  onPageSizeChange={(newPageSize) =>
                    setRowsPerPage(newPageSize)
                  }
                  getRowId={(row) => row._id}
                  checkboxSelection
                  selectionModel={selectionModel}
                  onSelectionModelChange={(newSelection) => {
                    console.log(newSelection);
                    setSelectionModel(newSelection);
                  }}
                />
              </div>
              <div className="form-group text-center">
                <button
                  type="submit"
                  className="btn-lg buttonlogin"
                  style={{ fontSize: "18px" }}
                  onClick={handleCreateBrands}
                >
                  Create Brands
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Display brands in a data grid table */}
      <style>
        {`
        .registration-container {
          width: 100%;
          padding: 50px;
          border-radius: 8px;
          background-color: #f7f7f7;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
            margin: auto;
          }
          .css-levciy-MuiTablePagination-displayedRows{
            margin: auto;
          }
          .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
            margin: 0
          }
        .container {
            padding: 20px;
          }
  
          .email-items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
          }
  
          .email-item {
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 10px;
          }
  
          .email-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
  
          .email-name {
            margin-right: 10px;
            font-weight: bold;
          }
  
          .disable-button {
            padding: 5px 10px;
            background-color: #ea8523;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          @media only screen and (max-width: 768px) {
            .registration-container {
              padding: 20px; /* Adjust padding for smaller screens */
            }

            .email-items {
              grid-template-columns: repeat(1, 1fr); /* Single column layout for brands */
            }

            /* Additional style adjustments for smaller screens */
            .email-item {
              margin-bottom: 20px;
            }

            .email-name {
              margin-right: 0;
            }

            .disable-button {
              margin-top: 10px;
            }

            .login-page-logo {
              max-width: 200px; /* Adjust logo size for smaller screens */
            }
          }
  
          .disable-button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }

        .form-group {
        //   margin: 5px;
          text-align: center;
          width: 50%;
          margin: 10px auto;
        }
        .error {
          color: #ff0000;
          font-size: 14px;
        }
        .buttonlogin, .buttonlogin:hover{
          background:#ea8523;
          color:#fff;
          border:none;
          outline:none;
          font-size:22px;
          margin-top: 10px
        }

        .registration-container input{
          font-size:18px;
          width:100%;
        }
        .registration-container input:active,.registration-container input:hover, .registration-container input:focus, .registration-container input:focus-visible{
          border:none !important;
          outline:none !important;
        }
        .registration-container label{
          font-size:18px;
          font-weight:500;
        }
        .registration-container input::placeholder{
          font-size:16px;
  
        }
        .almond-logo{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-page-logo{
          max-width:300px !important;
        }
        .login-page-logo{
          width: 80%;
        }

        .disabledStyl{
          background-color:green;
          color:#fffffff;
        }
        

      `}
      </style>
    </PageLayout>
  );
};

export default CreateVendorBrands;
