import React, { useState, useEffect } from "react";
import { useVendorId } from "../VendorIdContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PageLayout from "../Layout/Layout";
import {
  FaUser,
  FaBuilding,
  FaIdCard,
  FaGlobe,
  FaPhone,
  FaEnvelope,
  FaLock,
} from "react-icons/fa";
import { baseUrl } from "../constant";

const AssignServices = () => {
  const [values, setValues] = useState({
    selectedService: "",
    inputValue: "",
  });

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setValues({
      ...values,
      selectedService: e.target.value,
    });
  };

  const handleInputChange = (e) => {
    setValues({
      ...values,
      inputValue: e.target.value,
    });
  };

  const validationSchema = Yup.object({
    selectedService: Yup.string().required("Please select a option"),
    inputValue: Yup.string().trim().required("Please enter a details"),
  });

  const handleSubmit = (values) => {
    const { selectedService, inputValue } = values;
    const requestData = {
        [selectedService]: inputValue,
    };

    axios
      .post(`${baseUrl}/gratification/get-vendor-details`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Response from API:", response.data);
        const vendor_id = response?.data?.data?.data?._id;
        console.log("vendor__id", vendor_id)
        localStorage.setItem("vendor_id", vendor_id)
        if (response.status === 200) {
            Swal.fire({
              title: "Success",
              text: "Submit Successfully",
              icon: "success",
            });
            document.querySelector("form").reset();
            navigate("/registerForm");
          } else {
            Swal.fire({
                title: "error",
                text: response?.data?.message,
                icon: "error",
              });
          }
      })
      .catch((error) => {
        Swal.fire({
            title: "error",
            text: error.response.data.message,
            icon: "error",
          });
      });
  };

  return (
    <>
      <PageLayout>
        <div
          className="container"
          style={{
            backgroundColor: "",
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="registration-container">
            <div className="d-flex">
              <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
                <div className="h1 fw-bold mb-0 almond-logo">
                  <img
                    className="img-fluid login-page-logo"
                    src="/images/almond-logo.png"
                  />
                </div>
              </div>
              <h2 className="fs-1 mb-3 mt-5 pb-3 text-center"></h2>
            </div>
            <Formik
              initialValues={values}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, handleChange, handleSubmit, errors, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label style={{ float: "left" }}>Select Options</label>
                        <select
                          name="selectedService"
                          className="form-control"
                          onChange={handleChange}
                          value={values.selectedService}
                        >
                          <option value="">*Select Options</option>
                          <option value="email">Email</option>
                          <option value="organization_pan_number">
                            Pan Number
                          </option>
                        </select>
                        {errors.selectedService && touched.selectedService && (
                          <div className="error">{errors.selectedService}</div>
                        )}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="label">
                          <label style={{ float: "left" }}>Enter Details</label>
                        </div>
                        <input
                          type="text"
                          name="inputValue"
                          className="form-control"
                          placeholder="Enter Details"
                          value={values.inputValue}
                          onChange={handleChange}
                        />
                        {errors.inputValue && touched.inputValue && (
                          <div className="error">{errors.inputValue}</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn-lg buttonlogin">
                      Submit
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <style>
          {`
        .registration-container {
          width: 70%;
          padding: 50px;
          border-radius: 8px;
          background-color: #f7f7f7;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .form-group {
          margin: 10px;
          text-align: center;
          width: 100%;
        }
        .error {
          color: #ff0000;
          font-size: 14px;
        }
        .buttonlogin, .buttonlogin:hover{
          background:#1e2c42;
          color:#fff;
          border:none;
          outline:none;
          font-size:22px;
          margin-top: 20px
        }

        .registration-container input{
          font-size:18px;
          width:100%;
        }
        .registration-container input:active,.registration-container input:hover, .registration-container input:focus, .registration-container input:focus-visible{
          border:none !important;
          outline:none !important;
        }
        .registration-container label{
          font-size:18px;
          font-weight:500;
        }
        .registration-container input::placeholder{
          font-size:16px;
  
        }
        .almond-logo{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-page-logo{
          max-width:300px !important;
        }
        .login-page-logo{
          width: 80%;
        }
        

      `}
        </style>
      </PageLayout>
    </>
  );
};

export default AssignServices;
