import axios from "axios";
import { baseUrl } from "../constant";

export const getAllUsersData = async (page, limit) => {
    try {
        
    // console.log("page", page, "limit",limit, localStorage.getItem('token'))
        const response = await axios.post(`${baseUrl}/api/admin/users/list?page=${page}&limit=${limit}`,{}, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('token')}`
            }
        })
        
        return response;
    } catch (err) {
        return err;
    }
}