import React, { useState, useEffect } from "react";
import PageLayout from "../Layout/Layout";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import { baseUrl } from "../constant";

const MySwal = withReactContent(Swal);

const Profile = () => {
  const contact_person_name = localStorage.getItem("contact_person_name");
  const organization_name = localStorage.getItem("organization_name");
  const email = localStorage.getItem("email");
  const vendor_id = localStorage.getItem("vendor_id");
  const [role, setRole] = useState();

  const getRole = () => {
    const roles = typeof window != "undefined" && localStorage.getItem("role");
    console.log("role", roles);
    setRole(roles);
  };

  useEffect(() => {
    getRole();
  }, []);

  const [generatedToken, setGeneratedToken] = useState("");
  const [error, setError] = useState("");
  const [permanentToken, setPermanentToken] = useState("");
  const [copied, setCopied] = useState("");
  const [copiedtwo, setCopiedTwo] = useState("");

  const copyText = (text, id) => {
    navigator.clipboard
      ?.writeText(text)
      .then(() => {
        if (id === 1) {
          setCopied(true);
        } else {
          setCopiedTwo(true);
        }
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  const handleGenerateToken = () => {
    MySwal.fire({
      title: "Generate Token?",
      text: "Are you sure you want to generate a token?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes, generate it!",
      cancelButtonText: "No, cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const tokenFromLocalStorage = localStorage.getItem("token");
        const configHeaders = {
          headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
        };
        axios
          .post(
            `${baseUrl}/gratification/create-keys`,
            {
              vendor_id: vendor_id,
            },
            configHeaders
          )
          .then((response) => {
            console.log("Token generated:", response.data.data.permanent_token);
            const generatedTokenFromAPI = response.data.data.permanent_token;

            setGeneratedToken(generatedTokenFromAPI);
            MySwal.fire(
              "Token Generated!",
              "Your token has been generated.",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error generating token:", error);
            setError(error?.response?.data?.message);
            MySwal.fire("Error", error?.response?.data?.message, "error");
          });
      } else {
        MySwal.fire("Canceled", "Token generation was canceled.", "error");
      }
    });
  };

  const getVendorPermanentToken = async () => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };
      const res = await axios.get(
        `${baseUrl}/gratification/vendor-permanent-token?vendor_id=${vendor_id}`,
        configHeaders
      );
      setPermanentToken(res.data.data.permanent_token);
      localStorage.setItem("permanent_token", res.data.data.permanent_token);

      console.log(
        "🚀 ~ file: profile.js:73 ~ getVendorPermanentToken ~ res:",
        res
      );
      console.log(res.data.data.permanent_token);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShowToken = () => {
    getVendorPermanentToken();
  };

  useEffect(() => {
    const existingToken = generatedToken;
    if (existingToken) {
      setGeneratedToken(existingToken);
    }
    getVendorPermanentToken();
  }, []);

  return (
    <PageLayout>
      <div
        className="ms-4"
        style={{ width: "95%", marginTop: "10px", minHeight: "20vh" }}
      >
        {/* <h3>Profile</h3> */}
        <div
          style={{
            border: "1px solid #ccc",
            borderRadius: "8px",
            wordWrap: "break-word",
            maxWidth: "100%",
          }}
        >
          <div className="card p-3">
            <h4 style={{ color: "#95500c", fontWeight: 600 }}>
              Profile Details
            </h4>
            {role === "client" && (
              <div>
                <strong>Name:</strong> {contact_person_name}
              </div>
            )}

            {role === "super_admin" && (
              <div>{/* <strong>Name:</strong> {role} */}</div>
            )}
            <div>
              <strong>Email:</strong> {email}
            </div>
            <div>
              <strong>User Role:</strong>{" "}
              {role
                ? role
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : ""}
            </div>
          </div>
          {console.log("rolee", role, generatedToken)}
          {role === "client" && generatedToken && (
            <div style={{ padding: 12 }}>
              <strong>Permanent Token</strong>
              <span
                style={{ float: "right" }}
                onClick={() => copyText(permanentToken, 1)}
              >
                {!copied ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-solid fa-check"></i>
                )}
              </span>
              <p style={{ wordWrap: "break-word" }}>{generatedToken}</p>
            </div>
          )}
          {/* {error && <div className="error-message">{error}</div>} */}
          {console.log("roleep", role, permanentToken)}

          {permanentToken && role === "client" && (
            <div style={{ padding: 12 }}>
              <strong>Permanent Token</strong>
              <span
                style={{ float: "right" }}
                onClick={() => copyText(permanentToken, 1)}
              >
                {!copied ? (
                  <i className="fa-solid fa-copy"></i>
                ) : (
                  <i className="fa-solid fa-check"></i>
                )}
              </span>

              <p style={{ wordWrap: "break-word" }}>{permanentToken}</p>
            </div>
          )}
          {console.log("generatedToken", generatedToken,"permanentToken", permanentToken )}
          {role === "client" && !generatedToken && !permanentToken && (
            <div className="mt-3">
              <button className="btn-btn" onClick={handleGenerateToken}>
                Generate Token
              </button>
            </div>
          )}
        </div>
        {/* {!generatedToken?"":<div className="mt-3">
          <button className="btn btn-primary" onClick={handleShowToken}>
            Show Token
          </button>
        </div>}   */}

        <style>
          {`
            .btn-btn{
            background-color: #ea8523;
            padding: 5px 14px;
            border-radius: 10px;
            border: none;
            margin: 10px;
            }
            `}
        </style>
      </div>
    </PageLayout>
  );
};

export default Profile;
