import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { baseUrl } from "../constant";
import axios from "axios";
import Loader from "../../src/components/loader";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [totalCount, setTotalCount] = useState("");

  const columns = [
    { field: "id", headerName: "ID", width: 150 },
    {
      field: "credits",
      headerName: "credits",
      width: 150,
    },
    {
      field: "service_type",
      headerName: "service type",
      width: 200,
    },
    {
      field: "type",
      headerName: "type",
      width: 150,
    },
    {
        field: "status",
        headerName: "status",
        width: 150,
      },
      {
        field: "transaction_id",
        headerName: "transaction id",
        width: 250,
      },
      {
        field: "vault_balance",
        headerName: "wallet balance",
        width: 150,
      },
      {
        field: "vendor_id",
        headerName: "vendor id",
        width: 250,
      },
      {
        field: "vendor_transaction_id",
        headerName: "vendor transaction id",
        width: 250,
      },

      {
        field: "createdAt",
        headerName: "Created At",
        width: 250,
      },
      {
        field: "updatedAt",
        headerName: "Updated At",
        width: 250,
      },
  ];

  const getTransactions = async () => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const vendorId = localStorage.getItem("vendor_id");

      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const res = await axios.get(
        `${baseUrl}/gratification/vendor-transactions?vendor_id=${vendorId}&page=${page}&size=${size}&type=${typeFilter}&status=${statusFilter}`,
        configHeaders
      );
      console.log(
        "🚀 ~ file: transactions.js:42 ~ getTransactions ~ res:",
        res.data.data
      );

      const transactionsData = await res?.data?.data;
      setTotalCount(transactionsData?.totaCount)

      let filteredData = transactionsData?.data;

    // Apply filters based on type and status
    if (typeFilter) {
      filteredData = filteredData.filter((dt) =>
        dt.type.toLowerCase().includes(typeFilter.toLowerCase())
      );
    }

    // if (statusFilter) {
    //   filteredData = filteredData.filter((dt) =>
    //     dt.status.toLowerCase().includes(statusFilter.toLowerCase())
    //   );
    // }

    // Filter data based on searchTerm
    if (searchTerm) {
      filteredData = filteredData.filter((dt) =>
        dt.type.toLowerCase().includes(searchTerm.toLowerCase()) 
      );
    }

    let result = [];
    filteredData.map((dt, i) => {
      let updatedServiceType;
      const index = (page - 1) * size + i + 1;

      switch (true) {
        case dt?.service_type.toUpperCase().startsWith("QC"):
          updatedServiceType = "Quicksilver";
          break;
        case dt?.service_type.toLowerCase() === "vouchers":
          updatedServiceType = "Almonds Vouchers";
          break;
        default:
          updatedServiceType = dt?.service_type;
      }
      
          result.push({
          // ind: index,
          id: index,
          credits: dt?.credits,
          service_type: dt?.updatedServiceType,
          credits: dt?.credits,
          // service_type: dt?.service_type,
          type:dt?.type,
          status: dt?.status,
          transaction_id: dt?.transaction_id,
          vault_balance: dt.vault_balance,
          vendor_id: dt?.vendor_id,
          vendor_transaction_id: dt?.vendor_transaction_id,
          createdAt:new Date(dt.createdAt).toLocaleString('en-IN'),
          updatedAt:new Date(dt.updatedAt).toLocaleString('en-IN'),
        });
      });
  
      setTransactions(result);
      setPagination(transactionsData?.totaCount);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setTypeFilter("");
    setPage(1);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value); 
    setTypeFilter(value);
  };

  useEffect(() => {
    getTransactions();
    console.log("Pagination", pagination);
  }, [page, size, searchTerm]);

  return (
    <>
      <PageLayout>
        <Card className="mt-4" style={{ width: "100%" }}>
          <CardContent>
          <Typography
  variant="h5"
  component="div"
  style={{ fontWeight: 600 }}
>
  Transactions
  <TextField
    label="Search type"
    variant="outlined"
    value={searchTerm}
    onChange={handleSearch}
    style={{
      float: "right",
      marginTop: 0,
    }}
  />
  {searchTerm && (
    <Button
      variant="danger"
      size="sm"
      onClick={handleClearFilter}
      style={{
        float: "right",
        marginRight: "10px",
        marginTop: "10px",
        fontWeight: "600",
      }}
    >
      Clear Filter
    </Button>
  )}
</Typography>
            <div style={{ marginBottom: "20px" }}>
              <Typography variant="body1" color="textSecondary">
                Total Count: {pagination}
              </Typography>
            </div>
            
            <div style={{ height: "auto", marginTop: 40}}>
              {loading ? (
                <Loader />
              ) : transactions?.length === 0 ? (
                <div className="result-not-found">Data Not Found</div>
              ) : (
                <DataGrid
                  // rows={transactions}
                  // columns={columns}
                  // initialState={{
                  //   ...transactions.initialState,
                  //   pagination: { paginationModel: { pageSize: 10 } },
                  // }}
                  // pageSizeOptions={[5, 10, 25]}
                  // checkboxSelection
                  // disableRowSelectionOnClick
                  // autoHeight
                  // {...transactions}

                  rows={transactions}
                    columns={columns}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    pageSize={size}
                    rowCount={pagination}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                      setPage(newPage + 1);
                      console.log(newPage);
                    }}
                    onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                    autoHeight
                    {...transactions}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </PageLayout>
      <style>{`
        .react-toggle--checked .react-toggle-track {
          background-color: grey !important;
        }
        .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
          margin: auto;
        }
        .css-levciy-MuiTablePagination-displayedRows{
          margin: auto;
        }
        .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
          margin: 0
        }
        .css-t89xny-MuiDataGrid-columnHeaderTitle {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: var(--unstable_DataGrid-headWeight);
          font-size: 15px;
          font-weight: bold;
        }
        .css-1pnmrwp-MuiTypography-root {
          margin: 0;
          font-family: "Roboto","Helvetica","Arial",sans-serif;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 1.5;
          letter-spacing: 0.00938em;
          color: green;
      }
      `}</style>
    </>
  );
};

export default Transactions;
