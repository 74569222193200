import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { baseUrl } from "../constant";
import Loader from './loader';
import { useNavigate } from 'react-router-dom';

const Content = () => {
  const [loading, setLoading] = useState(true)
  const [coupanData, setCoupanData] = useState();
  const navigate = useNavigate();

  useEffect(() => {

    const interval = setTimeout(() => {

      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };
      console.log(tokenFromLocalStorage)
      axios.get(`${baseUrl}/gratification/allMerchantTranscations`, configHeaders).then((response) => {
        console.log("response", response);
        if (response?.data?.success === true) {

          setCoupanData(response?.data?.result);
          setLoading(false);
          console.log(coupanData);

        }

      }).catch((error) => {
        if (error?.response.status === 401) {
          navigate("/")
        }

        console.error('Error fetching data:', error, "axiosErrror", error?.response.status);
      });

    }, 10);

    return () => clearInterval(interval);

  }, [])
  return (

    <div className="container" >
      {loading ? (
        <Loader />
      ) : coupanData?.length === 0 ? (
        <div className="result-not-found">Data Not Found</div>
      ) : (
        <div className="row mt-5 ml-5 coupan-container">
          <div className="col-md-4 col-sm-6">
            <div className="card bg-c-blue order-card" style={{ maxHeight: '200px', overflow: 'auto' }}>
              <div className="card-block">
                <h6 className="m-b-20">Total Users</h6>
                <h2 className="text-right"><i className="fa fa-user f-left m-2" /><span>{coupanData?.totalUsers}</span></h2>
                {/* <p className="m-b-0">redeemed Coupan<span className="f-right">351</span></p> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card bg-c-green order-card" style={{ maxHeight: '200px', overflow: 'auto' }}>
              <div className="card-block">
                <h6 className="m-b-20">Total Coupons</h6>
                <h2 className="text-right"><i className="fa fa-credit-card f-left mt-2 me-2" />{coupanData?.totalCoupons}<span></span></h2>
                {/* <p className="m-b-0">Pending<span className="f-right">351</span></p> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card bg-c-yellow order-card" style={{ maxHeight: '200px', overflow: 'auto' }}>
              <div className="card-block">
                <h6 className="m-b-20">Total Used Coupons</h6>
                <h2 className="text-right"><i className="fa fa-credit-card f-left mt-2 me-2" /><span>{coupanData?.totalUsedCoupons}</span></h2>
                {/* <p className="m-b-0"> Orders<span className="f-right">351</span></p> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card bg-c-pink order-card" style={{ maxHeight: '200px', overflow: 'auto' }}>
              <div className="card-block">
                <h6 className="m-b-20">Total Unused Coupons</h6>
                <h2 className="text-right"><i className="fa fa-credit-card f-left mt-2 me-2" /><span>{coupanData?.totalUnusedCoupons}</span></h2>
                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
              </div>
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <div className="card  order-card" style={{ maxHeight: '200px', overflow: 'auto', backgroundColor: "orange" }}>
              <div className="card-block">
                <h6 className="m-b-20">Total Expired Coupons</h6>
                <h2 className="text-right"><i className="fa fa-credit-card f-left mt-2 me-2" /><span>{coupanData?.totalExpiredCoupons}</span></h2>
                {/* <p className="m-b-0">Completed Orders<span className="f-right">351</span></p> */}
              </div>
            </div>
          </div>
        </div>
      )}


    </div>


  );
};

export default Content;
