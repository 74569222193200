import { createContext, useContext, useState } from 'react';

const VendorIdContext = createContext();

export const VendorIdProvider = ({ children }) => {
  const [vendorId, setVendorId] = useState("");

  const setAndSaveVendorId = (id) => {
    setVendorId(id);
  };

  return (
    <VendorIdContext.Provider value={{ vendorId, setAndSaveVendorId }}>
      {children}
    </VendorIdContext.Provider>
  );
};

export const useVendorId = () => {
  return useContext(VendorIdContext);
};
