import React from 'react';
import PageLayout from '../Layout/Layout';
import UsersTable from '../components/usersTable';

const Users = () => {
  return (
    <PageLayout>
      <div className="ms-4" style={{ height: 400, width: "95%", marginTop: "10px" }}>
        <UsersTable title="Users Data" />
      </div>
      <style>
        {
          `
          .mc-main-container {
            position: fixed;
            margin-left: 250px;
          `
        }
      </style>
    </PageLayout>
  );
};

export default Users;
