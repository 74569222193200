import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import convertStatusCode from "../services/convertStatusCode";

function CouponCard({ coupon }) {
  const [couponData, setCouponData] = useState(null);
  const [usersData, setUsersData] = useState(null);
  const [showFullImage, setShowFullImage] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [currentDomain, setCurrentDomain] = useState("");
  let userData = null;

  useEffect(() => {
    if (coupon) {
      const array = Object.keys(coupon);
      const mapped = array.map((e, index) => {
        // console.log("e",e, index)
        if (e === "user") {
          userData = coupon[e];
        }
        let value = coupon[e];
        if (
          e === "created_at" ||
          e === "updated_at" ||
          e === "expireAt" ||
          e === "publishDate" ||
          e === "qwickcilver_expiry"
        ) {
          value = formatDate(value);
        } else if (e === "image") {
          setImageData(value);
          return null;
        }
        const obj = { label: e, value };
        return obj;
      });
      console.log("mapeed", mapped, "cou[on", coupon);
    }
    // console.log("userData", userData)
    setUsersData(userData);
    setCouponData(coupon);
  }, [coupon]);

  useEffect(() => {
    setCurrentDomain(window.location.pathname);
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const handleOpenImage = () => {
    setShowFullImage(true);
  };
  const handleCloseImage = () => {
    setShowFullImage(false);
  };
  return (
    <div className="mc-product-item">
      {/* <div className="mc-product-desc-container mc-coupon-code-container" style={{marginLeft: '-13px'}}>
          <h3 className="mc-product-name" style={{fontSize: '20px'}}>{couponData?.coupon_code || "__"}</h3>
          <div className="mc-price-container">
            <span>MRP: {" "}</span>
            <span className="max-price">
              {couponData?.currency?.symbol || ""}
              {couponData?.price_point || ""}
            </span>
          </div>
        </div> */}

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">Vendor Id</p>
        <h3 className="mc-coupon-title">{coupon?.vendor_id || "__"}</h3>
        {currentDomain.includes("/Products/view") && (
          <>
            <p className="mc-coupon-label">Product Id</p>
            <h3 className="mc-coupon-title">{coupon?.product_id || "__"}</h3>
          </>
        )}
        {currentDomain.includes("/Products/view") && (
          <>
            <p className="mc-coupon-label">SKU</p>
            <h3 className="mc-coupon-title">{coupon?.qc_sku || "__"}</h3>
          </>
        )}
        {currentDomain.includes("/Brands/view") && (
          <>
            <p className="mc-coupon-label">Brand Id</p>
            <h3 className="mc-coupon-description">
              {couponData?.brand_id || "__"}
            </h3>
          </>
        )}
        {currentDomain.includes("/Categories/view") && (
          <>
            <p className="mc-coupon-label">Category Id</p>
            <h3 className="mc-coupon-description">
              {couponData?.category_id || "__"}
            </h3>
          </>
        )}
      </div>

      <div className="mc-product-desc-container description">
        {currentDomain.includes("/Brands/view") && (
          <>
            <p className="mc-coupon-label">Brand Name</p>
            <h3 className="mc-coupon-description">
              {couponData?.brand_name || "__"}
            </h3>
          </>
        )}
        {currentDomain.includes("/Categories/view") && (
          <>
            <p className="mc-coupon-label">Category Name</p>
            <h3 className="mc-coupon-description">
              {couponData?.name || "__"}
            </h3>
          </>
        )}
        {currentDomain.includes("/Barnds/view") && (
          <>
            <p className="mc-coupon-label">Brand Code</p>
            <h3 className="mc-coupon-description">
              {couponData?.brand_code || "__"}
            </h3>
          </>
        )}

        {currentDomain.includes("/Categories/view") && (
          <>
            <p className="mc-coupon-label">QC Category Id</p>
            <h3 className="mc-coupon-description">
              {couponData?.qc_category_id || "__"}
            </h3>
          </>
        )}
        <p className="mc-coupon-label">Email</p>
        <h3 className="mc-coupon-description">{couponData?.email || "__"}</h3>
      </div>

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">Vendor Name</p>
        <h3 className="mc-coupon-description">
          {couponData?.contact_person_name || "__"}
        </h3>
        <p className="mc-coupon-label">OrganiZation Name</p>
        <h3 className="mc-coupon-description">
          {couponData?.organization_name || "__"}
        </h3>
      </div>

      <div className="mc-product-desc-container description">
        <p className="mc-coupon-label">Created At</p>
        <h3 className="mc-coupon-title">{couponData?.createdAt || "N/A"}</h3>
        <p className="mc-coupon-label">Updated At</p>
        <h3 className="mc-coupon-title">{couponData?.updatedAt || "N/A"}</h3>
        <p className="mc-coupon-label">Status</p>
        <h3 className="mc-coupon-title">{couponData?.status || "True"}</h3>
      </div>
    </div>
  );
}

export default CouponCard;
