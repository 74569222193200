import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { baseUrl } from "../constant";
import axios from "axios";
import Loader from "../../src/components/loader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";

const Services = () => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [providerFilter, setProviderFilter] = useState("");

  const columns = [
    { field: "id", headerName: "id", width: 70 },
    {
      field: "name",
      headerName: "Name",
      width: 150,
    },
    {
      field: "service_provider",
      headerName: "Provider",
      width: 150,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 250,
    },
  ];

  const getServices = async () => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };
      const res = await axios.get(
        `${baseUrl}/gratification/get-all-services?page=${page}&size=${size}&service_provider=${providerFilter}`,
        configHeaders
      );
      const servicesData = res?.data?.data;
      console.log("🚀 ~ getServices ~ servicesData:", servicesData);

      let filteredResult = [];
      servicesData?.data
        .filter(
          (dt) =>
            dt.service_provider
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) &&
            dt.service_provider
              .toLowerCase()
              .includes(providerFilter.toLowerCase())
        )
        .map((dt, i) => {
          const capitalizedProvider = dt.service_provider
            .toLowerCase()
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
            const capitalizedName = dt?.name
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ") 
            .replace("Incentive Payout", "Bank Transfer");

          filteredResult.push({
            id: (page - 1) * size + i + 1,
            name: capitalizedName,
            service_provider: capitalizedProvider,
            createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
            updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
          });
        });
      setServices(filteredResult);
      setPagination(servicesData?.totaCount);
      setLoading(false);
      console.log("Pagination: ", pagination);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setProviderFilter("");
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setProviderFilter(value);
    // setPage(1);
  };

  useEffect(() => {
    getServices();
    console.log("Pagination", pagination);
  }, [page, size, , searchTerm, providerFilter]);

  return (
    <>
      <PageLayout>
        <Card className="mt-4" style={{ width: "100%" }}>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              style={{ fontWeight: 600 }}
            >
              Services
              <TextField
                label="Search Service Provider"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  float: "right",
                  marginTop: 0,
                }}
              />
              {searchTerm && (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={handleClearFilter}
                  style={{
                    float: "right",
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "600",
                  }}
                >
                  Clear Filter
                </Button>
              )}
            </Typography>
            <div style={{ marginBottom: "20px" }}>
              <Typography variant="body1" color="textSecondary">
                Total Count: {pagination}
              </Typography>
            </div>
            <div style={{ width: "100%" }}>
              {loading ? (
                <Loader />
              ) : services?.length === 0 ? (
                <div className="result-not-found">Data Not Found</div>
              ) : (
                <div>
                  <DataGrid
                    rows={services}
                    columns={columns}
                    rowsPerPageOptions={[5, 10, 20]}
                    pagination
                    pageSize={size}
                    rowCount={pagination}
                    paginationMode="server"
                    onPageChange={(newPage) => {
                      setPage(newPage + 1);
                      console.log(newPage);
                    }}
                    onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                    autoHeight
                    {...services}
                  />
                </div>
              )}
            </div>
          </CardContent>
        </Card>
      </PageLayout>
      <style>{`
        .react-toggle--checked .react-toggle-track {
          background-color: grey !important;
        }

        .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
          margin: auto;
        }
        .css-levciy-MuiTablePagination-displayedRows{
          margin: auto;
        }
        .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
          margin: 0
        }

        .css-t89xny-MuiDataGrid-columnHeaderTitle {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-weight: var(--unstable_DataGrid-headWeight);
          font-size: 15px;
          font-weight: bold;
        }
        .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
          margin: auto;
        }
        .css-levciy-MuiTablePagination-displayedRows{
          margin: auto;
        }
        .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
          margin: 0
        }

        .css-bhp9pd-MuiPaper-root-MuiCard-root {
          background-color: #fff;
          color: rgba(0, 0, 0, 0.87);
          -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          border-radius: 4px;
          box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
          overflow: scroll !important;
      }

      .css-1pnmrwp-MuiTypography-root {
        margin: 0;
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        font-weight: 400;
        font-size: 1.2rem;
        line-height: 1.5;
        letter-spacing: 0.00938em;
        color: green;
    }

    .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
      font: inherit;
      letter-spacing: inherit;
      color: currentColor;
      padding: 4px 0 5px;
      border: 0;
      box-sizing: content-box;
      background: none;
      height: 1.4375em;
      margin: 0;
      -webkit-tap-highlight-color: transparent;
      display: block;
      min-width: 0;
      width: 100%;
      -webkit-animation-name: mui-auto-fill-cancel;
      animation-name: mui-auto-fill-cancel;
      -webkit-animation-duration: 10ms;
      animation-duration: 10ms;
      padding: 16.5px 46px;
  }
      `}</style>
    </>
  );
};

export default Services;
