import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { baseUrl } from "../constant";
import axios from "axios";
import Loader from "../../src/components/loader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as xlsx from 'xlsx/xlsx.mjs';

const AllTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [pagination, setPagination] = useState(0);
  const [serviceNameFilter, setServiceNameFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const columns = [
    { field: "id", headerName: "ID", width: 250 },
    {
      field: "credits",
      headerName: "Amount",
      width: 250,
    },
    {
      field: "service_type",
      headerName: "service type",
      width: 250,
    },
    {
      field: "merchant_account",
      headerName: "Merchant Account",
      width: 250,
    },
    {
      field: "type",
      headerName: "type",
      width: 250,
    },
    {
      field: "status",
      headerName: "status",
      width: 250,
    },
    {
      field: "transaction_id",
      headerName: "transaction id",
      width: 250,
    },
    {
      field: "organization_name",
      headerName: "Organization Name",
      width: 250,
    },
    {
      field: "vault_balance",
      headerName: "wallet balance",
      width: 250,
    },
    {
      field: "vendor_id",
      headerName: "vendor id",
      width: 250,
    },
    {
      field: "vendor_transaction_id",
      headerName: "vendor transaction id",
      width: 250,
    },

    {
      field: "createdAt",
      headerName: "Created At",
      width: 250,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 250,
    },
  ];

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      getAllTransactionsWithDate();
    } else {
      getAllTransactions();
    }
  }, [startDate, endDate]);

  const formattedStartDate = startDate?.toISOString().split('T')[0] || ''
  const formattedEndDate = endDate?.toISOString().split('T')[0] || ''


  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null); // Reset end date if it's before the new start date
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      return; // Prevent selecting end date before start date
    }
    setEndDate(date);
  };


  const handleFilterModelChange = (model) => {
    const columnField = model?.items[0]?.columnField;
    const value = model?.items[0]?.value;
    console.log("hgfghgtg", model);
    handleFilterApi(
      page,
      size,
      columnField,
      value,
      searchTerm,
      serviceNameFilter,
    );
  };

  const handleFilterApi = async (page, size, columnField, value) => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");

      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const filterObject = {};
    filterObject[columnField] = value;

      const apiRequest = {
        ...filterObject,
      };

      localStorage.setItem("filterCriteria", JSON.stringify(filterObject));

      const res = await axios.post(
        `${baseUrl}/gratification/get-all-transactions?page=${page}&size=${size}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        apiRequest,
        configHeaders
      );

      console.log(
        "🚀 ~ file: transactions.js:42 ~ getTransactions ~ res:",
        res.data.data
      );

      const transactionsData = await res?.data?.data;

      let filteredData = transactionsData.data;
      console.log(transactionsData, "filteredData", filteredData);

      let result = [];
      filteredData.map((dt, i) => {
        // const index = (page - 1) * size + i + 1;
        let updatedServiceType;

        switch (true) {
          case dt?.service_type?.toUpperCase().startsWith("QC"):
            updatedServiceType = "Quicksilver";
            break;
          case dt?.service_type?.toLowerCase() === "vouchers":
            updatedServiceType = "Almonds Vouchers";
            break;
          default:
            updatedServiceType = dt?.service_type;
        }
        result.push({
          // ind: index,
          id: (page - 1) * size + i + 1,
          credits: dt?.credits,
          merchant_account: updatedServiceType,
          service_type: updatedServiceType,
          organization_name: dt?.vendorData?.organization_name,
          type: dt?.type,
          status: dt?.status,
          transaction_id: dt?.transaction_id,
          vault_balance: dt.vault_balance,
          vendor_id: dt?.vendor_id,
          msisdn: dt?.msisdn,
          vendor_transaction_id: dt?.vendor_transaction_id,
          createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
          updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
        });
      });
      {
        console.log("tr", result);
      }

      setTransactions(result);
      setPagination(transactionsData?.totaCount);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleExportFile = async () => {
    try {
      setLoading(true);

      const filterCriteria = JSON.parse(localStorage.getItem("filterCriteria")) || {};
  
      const tokenFromLocalStorage = localStorage.getItem("token");
    const configHeaders = {
      headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
    };

    const res = await axios.post(
      `${baseUrl}/gratification/get-all-transactions?page=1&size=${pagination}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      filterCriteria,
      configHeaders
    );
  
      const transactionsData = res?.data?.data.data;
  
      let allTransactions = transactionsData.map((dt, i) => {
        let updatedServiceType;
        console.log("hbh", dt?.service_type)
  
        switch (true) {
          case dt?.service_type?.toUpperCase().startsWith("QC"):
            updatedServiceType = "Quicksilver";
            break;
          case dt?.service_type?.toLowerCase() === "vouchers":
            updatedServiceType = "Almonds Vouchers";
            break;
          default:
            updatedServiceType = dt?.service_type;
        }
  
        return {
          id: i + 1,
          credits: dt?.credits,
          merchant_account: updatedServiceType,
          service_type: updatedServiceType,
          organization_name: dt?.vendorData?.organization_name,
          type: dt?.type,
          status: dt?.status,
          transaction_id: dt?.transaction_id,
          vault_balance: dt.vault_balance,
          vendor_id: dt?.vendor_id,
          msisdn: dt?.msisdn,
          vendor_transaction_id: dt?.vendor_transaction_id,
          createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
          updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
        };
      });
      const ws = xlsx.utils.json_to_sheet(allTransactions);

      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Transactions");
  
      
      xlsx.writeFile(wb, "transactions.xlsx");
  
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  
  const getAllTransactions = async () => {
      
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");

      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const res = await axios.post(
        `${baseUrl}/gratification/get-all-transactions?page=${page}&size=${size}`,
        {
          // service_name: serviceNameFilter,
          // status: statusFilter,
        },
        configHeaders
      );
      console.log(
        "🚀 ~ file: transactions.js:42 ~ getTransactions ~ res:",
        res.data.data
      );

      const transactionsData = await res?.data?.data;

      let filteredData = transactionsData.data;
      console.log(transactionsData, "filteredData", filteredData);

      if (serviceNameFilter) {
        filteredData = filteredData.filter((dt) =>
          dt.type.toLowerCase().includes(serviceNameFilter.toLowerCase())
        );
      }

      // if (statusFilter) {
      //   filteredData = filteredData.filter((dt) =>
      //     dt.status.toLowerCase().includes(statusFilter.toLowerCase())
      //   );
      // }

      if (searchTerm) {
        filteredData = filteredData.filter((dt) =>
          dt.type.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      let result = [];
      filteredData.map((dt, i) => {
        // const index = (page - 1) * size + i + 1;
        let updatedServiceType;

        switch (true) {
          case dt?.service_type?.toUpperCase().startsWith("QC"):
            updatedServiceType = "Quicksilver";
            break;
          case dt?.service_type?.toLowerCase() === "vouchers":
            updatedServiceType = "Almonds Vouchers";
            break;
          default:
            updatedServiceType = dt?.service_type;
        }
        result.push({
          // ind: index,
          id: (page - 1) * size + i + 1,
          credits: dt?.credits,
          merchant_account: updatedServiceType,
          service_type: updatedServiceType,
          organization_name: dt?.vendorData?.organization_name,
          type: dt?.type,
          status: dt?.status,
          transaction_id: dt?.transaction_id,
          vault_balance: dt.vault_balance,
          vendor_id: dt?.vendor_id,
          msisdn: dt?.msisdn,
          vendor_transaction_id: dt?.vendor_transaction_id,
          createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
          updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
        });
      });
      {
        console.log("tr", result);
      }

      setTransactions(result);
      setPagination(transactionsData?.totaCount);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllTransactionsWithDate = async () => {
      
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");

      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const res = await axios.post(
        `${baseUrl}/gratification/get-all-transactions?page=${page}&size=${size}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
        {
          // service_name: serviceNameFilter,
          // status: statusFilter,
        },
        configHeaders
      );
      console.log(
        "🚀 ~ file: transactions.js:42 ~ getTransactions ~ res:",
        res.data.data
      );

      const transactionsData = await res?.data?.data;

      let filteredData = transactionsData.data;
      console.log(transactionsData, "filteredData", filteredData);

      if (serviceNameFilter) {
        filteredData = filteredData.filter((dt) =>
          dt.type.toLowerCase().includes(serviceNameFilter.toLowerCase())
        );
      }

      // if (statusFilter) {
      //   filteredData = filteredData.filter((dt) =>
      //     dt.status.toLowerCase().includes(statusFilter.toLowerCase())
      //   );
      // }

      if (searchTerm) {
        filteredData = filteredData.filter((dt) =>
          dt.type.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      let result = [];
      filteredData.map((dt, i) => {
        // const index = (page - 1) * size + i + 1;
        let updatedServiceType;

        switch (true) {
          case dt?.service_type?.toUpperCase().startsWith("QC"):
            updatedServiceType = "Quicksilver";
            break;
          case dt?.service_type?.toLowerCase() === "vouchers":
            updatedServiceType = "Almonds Vouchers";
            break;
          default:
            updatedServiceType = dt?.service_type;
        }
        result.push({
          // ind: index,
          id: (page - 1) * size + i + 1,
          credits: dt?.credits,
          merchant_account: updatedServiceType,
          service_type: updatedServiceType,
          organization_name: dt?.vendorData?.organization_name,
          type: dt?.type,
          status: dt?.status,
          transaction_id: dt?.transaction_id,
          vault_balance: dt.vault_balance,
          vendor_id: dt?.vendor_id,
          msisdn: dt?.msisdn,
          vendor_transaction_id: dt?.vendor_transaction_id,
          createdAt: new Date(dt.createdAt).toLocaleString("en-IN"),
          updatedAt: new Date(dt.updatedAt).toLocaleString("en-IN"),
        });
      });
      {
        console.log("tr", result);
      }

      setTransactions(result);
      setPagination(transactionsData?.totaCount);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    setServiceNameFilter("");
    setPage(1);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    setServiceNameFilter(value);
    setPage(1);
  };

  useEffect(() => {
    getAllTransactions();
    console.log("Pagination", pagination);
  }, [page, size, searchTerm, serviceNameFilter]);

  return (
    <>
      <PageLayout>
        <Card className="mt-4" style={{ width: "100%" }}>
          <CardContent>
            <Typography
              variant="h5"
              component="div"
              style={{ fontWeight: 600 }}
            >
              Transactions
              {/* <TextField
                label="Search type"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearch}
                style={{
                  float: "right",
                  marginTop: 0,
                }}
              /> */}
              {searchTerm && (
                <Button
                  variant="danger"
                  size="sm"
                  onClick={handleClearFilter}
                  style={{
                    float: "right",
                    marginRight: "10px",
                    marginTop: "10px",
                    fontWeight: "600",
                  }}
                >
                  Clear Filter
                </Button>
              )}
            </Typography>
            <div style={{ marginBottom: "20px" }}>
              <Typography variant="body1" color="textSecondary">
                Total Count: {pagination}
              </Typography>
            </div>

            <div className="date-filter d-flex align-items-center">
                <div className="mr-2">
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    placeholderText="Start Date"
                    className="form-control"
                  />
                </div>
                <div className="mr-2">
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    minDate={startDate} // Set minimum date for the end date picker
                    placeholderText="End Date"
                    className="form-control"
                  />
                </div>
                <button className="btn  btn-danger" onClick={handleClearDates}>
                  Clear
                </button>
                <button className="btn" style={{ marginLeft: '10px', backgroundColor: '#ea8523', color: 'white'}}
                onClick={
                  handleExportFile
                }>
                Export File
              </button>
              </div>

            <div style={{ height: "auto", marginTop: 40 }}>
              {loading ? (
                <Loader />
              ) : transactions?.length === 0 ? (
                <div className="result-not-found">Data Not Found</div>
              ) : (
                <DataGrid
                  rows={transactions}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  pageSize={size}
                  rowCount={pagination}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPage(newPage + 1);
                    console.log(newPage);
                  }}
                  onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                  autoHeight
                  onFilterModelChange={handleFilterModelChange}
                  {...transactions}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </PageLayout>
      <style>{`
        .react-toggle--checked .react-toggle-track {
                    background-color: grey !important;
                  }
                  .css-t89xny-MuiDataGrid-columnHeaderTitle {
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    font-weight: var(--unstable_DataGrid-headWeight);
                    font-size: 15px;
                    font-weight: bold;
                  }
                  .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
                    margin: auto;
                  }
                  .css-levciy-MuiTablePagination-displayedRows{
                    margin: auto;
                  }
                  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
                    margin: 0
                  }
          
          
                  .css-bhp9pd-MuiPaper-root-MuiCard-root {
                    background-color: #fff;
                    color: rgba(0, 0, 0, 0.87);
                    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                    border-radius: 4px;
                    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
                    overflow: scroll !important;
                }
          
                .css-1pnmrwp-MuiTypography-root {
                  margin: 0;
                  font-family: "Roboto","Helvetica","Arial",sans-serif;
                  font-weight: 400;
                  font-size: 1.2rem;
                  line-height: 1.5;
                  letter-spacing: 0.00938em;
                  color: green;
              }
      `}</style>
    </>
  );
};

export default AllTransactions;
