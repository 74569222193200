import React from 'react'

function convertStatusCode(status) {
  try{
    switch (status) {
        case 0:
          // Handle status code 0
          return "Coupon Generated"
          break;
        case 1:
          // Handle status code 1
          return "Checking Status"
          break;
        case 2:
          // Handle status code 2
          return "Coupon Redeemed"
          break;
        case 3:
          // Handle status code 3
          return "Click To Activate"
          break;
        case 5:
          // Handle status code 3
          return "Admin Activate Coupon"
          break;
        default:
          // Handle other status codes
         return "Admin Activate Coupon"
      }
  }catch(err){
    console.log("error in converting status of coupon")
    return err;
  }
}

export default convertStatusCode