import React from "react";
import Login from "./pages/Login";
import "bootstrap/dist/css/bootstrap.min.css";
import Main from "./pages/Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CouponData from "./pages/CouponData";
import Content from "./components/Content";
import Coupans from "./pages/Coupans";
import AllUsersData from "./pages/AllUsersData";
import DispatchCoupons from "./pages/DispatchCoupon";
import Setting from "./pages/setting";
import Upi from "./pages/upi";
import Vouchers from "./pages/vouchers";
import Insurance from "./pages/insurance";
import CouponCard from "./pages/coupon_card";
import UploadVouchers from "./pages/uploadVouchers";
import Recharge from "./pages/mobile_recharge";
import DTH from "./pages/DTH";
import Users from "./pages/users";
import Profile from "./pages/profile";
import Registration from "./pages/registration";
import Vendors from './pages/vendors';
import Services from './pages/services';
import Transactions from "./pages/transactions";
import AllTransactions from "./pages/allTransactions";
import RegisterForm from "./pages/registerForm";
import { VendorIdProvider } from "./VendorIdContext";
import CreateAdmin from "./pages/CreateAdmin";
import ClientInformation from "./pages/ClientInformation";
import CreateVendorBrands from "./pages/CreateVendorBrands";
import CreateRole from "./pages/CreateRole";
import CreateBrand from "./pages/CreateBrand";
import AssignServices from "./pages/assignServices";
import UpdateAwb from "./pages/updateAwb";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Main />} />
        <Route path="/registerForm" element={<RegisterForm />} />
        <Route path="/assignServices" element={<AssignServices />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/CreateRole" element={<CreateRole />} />
        {/* <Route path="/CreateServices" element={<CreateServices />} /> */}
        {/* <Route path="/Brands" element={<Brands />} /> */}
        <Route path="/Brands/view/:id" element={<CouponData />} />
        <Route path="/Categories/view/:id" element={<CouponData />} />
        <Route path="/Products/view/:id" element={<CouponData />} />
        {/* <Route path="/Orders" element={<Orders />} /> */}
        {/* <Route path="/Products" element={<Product />} /> */}
        {/* <Route path="/AdminProduct" element={<AdminProduct />} /> */}
        <Route path="/CreateVendorBrands" element={<CreateVendorBrands />} />
        {/* <Route path="/CreateVendorCategory" element={<CreateVendorCategories />} /> */}
        {/* <Route path="/CreateVendorProduct" element={<CreateVendorProduct />} /> */}
        {/* <Route path="/Categories" element={<Categories />} /> */}
        {/* <Route path="/GlobalAccountDetails" element={<GlobalAccountDetails />} /> */}
        <Route path="/coupons" element={<Coupans />} />
        {/* <Route path="/coupon" element={<Coupans/>}/> */}
        <Route path="/mobile_recharge" element={<Recharge />} />
        <Route path="/upi" element={<Upi />} />
        <Route path="/CreateAdmin" element={<CreateAdmin />} />
        <Route path="/CreateBrand" element={<CreateBrand />} />
        <Route path="/ClientInformation" element={<ClientInformation />} />
        <Route path="/vouchers" element={<Vouchers />} />
        <Route path="/insurance" element={<Insurance />} />
        <Route path="/coupon_card" element={<CouponCard />} />
        <Route path="/vendors" element={<Vendors />} />
        <Route path="/transactions" element={<Transactions />} />
        <Route path="/allTransactions" element={<AllTransactions />} />
        <Route path="uploadVouchers" element={<UploadVouchers />} />
        <Route path="/services" element={<Services />} />
        <Route path="/dth" element={<DTH />} />
        {/* <Route path="/incentive_payout" element={<IncentivePayout />} /> */}
        {/* <Route path="/bharat_bill" element={<BharatBill />} /> */}
        {/* <Route path="/amazon_pay" element={<AmazonPay />} /> */}
        <Route path="/dth" element={<DTH />} />
        {/* <Route path="/paytm" element={<Paytm />} /> */}
        <Route path="/setting" element={<Setting />} />
        <Route path="/users" element={<Users />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="update-awb" element={<UpdateAwb />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
