import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import { baseUrl } from "../constant";
import Loader from "../components/loader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const Recharge = () => {
const [recharge, setRecharge] = useState([])
const [loading, setLoading] = useState(true);
const [pagination, setPagination] = useState(0);
const [page, setPage] = useState(1);
const [size, setSize] = useState(10);


  const columns = [
    { field: "id", headerName: "id", width: 150 },
    {
      field: "service_type",
      headerName: "ID",
      width: 150,
    },
    {
      field: "service_type",
      headerName: "Service Type",
      width: 150,
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "vendor_id",
      headerName: "Client ID",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
    },
    {
      field: "credits",
      headerName: "Credits",
      width: 150,
    },
    {
      field: "vault_balance",
      headerName: "Vault Balance",
      width: 150,
    },
    {
      field: "is_credit_used",
      headerName: "Is Credit Used",
      width: 150,
    },
    {
      field: "transaction_id",
      headerName: "Transaction ID",
      width: 200,
    },
    {
      field: "vendor_transaction_id",
      headerName: "Client Transaction ID",
      width: 200,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 200,
    },
  ];

  const getRecharge = async () => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      
      const requestBody = {
        vendor_id: localStorage.getItem("vendor_id"),
        type: "mobile_recharge",
      };

      const res = await axios.post(
        `${baseUrl}/gratification/get-all-services-by-type?page=${page}&size=${size}`, 
        requestBody,
        configHeaders
      );
      console.log("🚀 ~ file: vendors.js:72 ~ getRecharge ~ res:", res)
      
      const RechargeData = await res?.data?.data;
      console.log("🚀 ~ file: vendors.js:67 ~ getRecharge ~ RechargeData:", RechargeData)
      let result = [];
      RechargeData?.data?.map((item, i) => {
        result.push({
          id: (page - 1) * size + i + 1,
      service_type: item.service_type,
      type: item.type,
      vendor_id: item.vendor_id,
      status: item.status,
      credits: item.credits,
      vault_balance: item.vault_balance,
      is_credit_used: item.is_credit_used,
      transaction_id: item.transaction_id,
      vendor_transaction_id: item.vendor_transaction_id,
      createdAt: new Date(item.createdAt).toLocaleString('en-IN'),
      updatedAt: new Date(item.updatedAt).toLocaleString('en-IN')
        });
      });
      setRecharge(result);
      setPagination(RechargeData?.totaCount);
      setLoading(false);
      console.log("Pagination: ", pagination);
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRecharge();
    console.log("Pagination", pagination);
  }, [page, size]);
  

  return (
    <>
      <PageLayout>
        <Card className="mt-4" style={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h5" component="div" style={{color: "#95500c", fontWeight: 600, marginBottom: 10}}>
              Clients
            </Typography>
            <div style={{ height: "auto" }}>
              {loading ? (
                <Loader />
              ) : recharge?.length === 0 ? (
                <div className="result-not-found">Data Not Found</div>
              ) : (
                <DataGrid
                  rows={recharge}
                  columns={columns}
                  rowsPerPageOptions={[5, 10, 20]}
                  pagination
                  pageSize={size}
                  rowCount={pagination}
                  paginationMode="server"
                  onPageChange={(newPage) => {
                    setPage(newPage + 1);
                    console.log(newPage);
                  }}
                  onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                  autoHeight
                  {...recharge}
                />
              )}
            </div>
          </CardContent>
        </Card>
        <style>{`

      .react-toggle--checked .react-toggle-track {
        background-color: grey !important;
      }

      .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
        margin: auto;
      }
      .css-levciy-MuiTablePagination-displayedRows{
        margin: auto;
      }
      .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
        margin: 0
      }
      
      .css-t89xny-MuiDataGrid-columnHeaderTitle {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: var(--unstable_DataGrid-headWeight);
        font-size: 15px;
        font-weight: bold;
      }
  `}</style>
      </PageLayout>
    </>
  );
};

export default Recharge;


// import React, { useEffect, useState } from "react";
// import PageLayout from "../Layout/Layout";
// import { DataGrid } from "@mui/x-data-grid";
// import axios from 'axios';

// const Recharge = () => {
//   const [pageState, setPageState] = useState({
//     isLoading: false,
//     data: [],
//     total: 0,
//     page: 1,
//     pageSize: 10
//   })

//   const columns = [
//     {
//       field: "id",
//       headerName: "ID",
//       width: 70,
//     },
//     {
//       field: "userId",
//       headerName: "User ID",
//       width: 70,
//     },
//     {
//       field: "title",
//       headerName: "Title",
//       width: 200,
//     },
//     {
//       field: "body",
//       headerName: "Body",
//       flex: 1,
//     },
//   ];

//   useEffect(() => {
//     const fetchData = async () => {
//       setPageState(old => ({ ...old, isLoading: true }));
//       try {
//         const response = await axios.get(`https://jsonplaceholder.typicode.com/posts`, {
//           params: {
//             _page: pageState.page,
//             _limit: pageState.pageSize
//           }
//         });
//         setPageState(old => ({
//           ...old,
//           isLoading: false,
//           data: response.data,
//           total: response.headers['x-total-count']
//         }));
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setPageState(old => ({ ...old, isLoading: false }));
//       }
//     };
//     fetchData();
//   }, [pageState.page, pageState.pageSize]);



//   return (
//     <>
//       <PageLayout>
//       <div
//           className="ms-4 table_box"
//           style={{ height: 500, width: "95%", marginTop: "10px" }}
//         >
//           <DataGrid
//         autoHeight
//         rows={pageState.data}
//         rowCount={pageState.total}
//         loading={pageState.isLoading}
//         rowsPerPageOptions={[10, 30, 50, 70, 100]}
//         pagination
//         page={pageState.page - 1}
//         pageSize={pageState.pageSize}
//         paginationMode="server"
//         onPageChange={(newPage) => {
//           setPageState(old => ({ ...old, page: newPage + 1 }))
//         }}
//         onPageSizeChange={(newPageSize) => setPageState(old => ({ ...old, pageSize: newPageSize }))}
//         columns={columns}
//       />
//         </div>
//       </PageLayout>
//     </>
//   );
// };

// export default Recharge;
