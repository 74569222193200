import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import PageLayout from "../Layout/Layout";
import { baseUrl } from "../constant";
import Loader from "../../src/components/loader";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";

const CreateBrand = () => {
    const [brands, setBrands] = useState([]);
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const columns = [
    { field: "_id", headerName: "ID", width: 250 },
    {
      field: "brand_name",
      headerName: "brand_name",
      width: 200,
    },
    {
      field: "brand_code",
      headerName: "brand_code",
      width: 200,
    },
    {
        field: "ind",
        headerName: "Action",
        width: 200,
        // headerAlign: "center",
        renderCell: (cellValues) => {
          console.log("cellValues: ", cellValues);
      
          const isStatusOne = cellValues.row.status === 1;
      
          return (
            <div className="td">
              <Tippy
                arrow={false}
                className="tippy21"
                content={<span>Toggle Status</span>}
              >
                <label className="togglelabel">
                  <Toggle
                    className=""
                    defaultChecked={cellValues.row.id === "Active" || isStatusOne}
                    icons={false}
                    onChange={() => handleDisableBrand(cellValues.row._id)}
                  />
                </label>
              </Tippy>
            </div>
          );
        },
      }
      
  ];
  

  const fetchBrands = async () => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };
      const response = await axios.get(
        `${baseUrl}/gratification/fetch-brands`,
        configHeaders
      );
      // console.log("🚀 ~ file: CreateBrand.js:25 ~ fetchBrands ~ response:", response)
      const brands = response.data.data;
      console.log(
        "🚀 ~ file: CreateBrand.js:27 ~ fetchBrands ~ brands:",
        brands
      );
      let result = [];
      brands?.map((dt, i) => {
        result.push({
          _id: dt?._id,
          brand_name: dt?.brand_name,
          brand_code: dt?.brand_code,
          status: dt?.status,
        });
      });
      setBrands(result);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  useEffect(() => {
    fetchBrands();
  }, []);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await axios.post(
        `${baseUrl}/gratification/create-brand`,
        {
          brand_name: values.brand,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data && response.data.statusCode === 200) {
        Swal.fire({
          title: "Success",
          text: "Brand Created Successfully",
          icon: "success",
        });
        // Swal.close();
        resetForm();
        const updatedResponse = await axios.get(
          `${baseUrl}/gratification/fetch-brands`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (
          updatedResponse.data &&
          updatedResponse.data.data &&
          Array.isArray(updatedResponse.data.data)
        ) {
          setBrands(updatedResponse.data.data);
        } else {
          console.error("Failed to fetch updated brands list");
        }
      } else if (
        response &&
        response.data &&
        response.data.statusCode === 409
      ) {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
        });
      } else if (response && response.data && response.data.status === false) {
        Swal.fire({
          title: "Error",
          text: response.data.message,
          icon: "error",
        });
      } else {
        console.error("Failed to create brand");
        Swal.fire({
          title: "Error",
          text: "Failed to create brand",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Error occurred:", error);
      Swal.fire({
        title: "Error",
        text: error?.response?.data?.message,
        icon: "error",
      });
    }
  };

  const handleDisableBrand = async (brandId) => {
    try {
      const response = await axios.put(
        `${baseUrl}/gratification/inactive-brand/${brandId}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response && response.data && response.data.statusCode === 200) {
        const updatedBrands = brands.map((brand) => {
          if (brand._id === brandId) {
            return { ...brand };
        }
        return brand;
    });

        fetchBrands();
        setBrands(updatedBrands);

        Swal.fire({
          title: "Success",
          text: response?.data?.message,
          icon: "success",
        });
        console.log("hdvg", response?.data?.message,);
      } else {
        console.error("Failed to disable brand");
        Swal.fire({
          title: "Error",
          text: "Failed to disable brand",
          icon: "error",
        });
      }
 
    } catch (error) {
        console.error("Error occurred:", error);
        Swal.fire({
          title: "Error",
          text: error?.response?.data?.message || "An error occurred",
          icon: "error",
        });
      }
  };

  

  useEffect(() => {
    console.log(brands);
  }, [brands]);

  const validationSchema = Yup.object().shape({
    brand: Yup.string()
      .trim()
      .matches(
        /^[a-z]+$/,
        "Brand name must contain only lowercase alphabetic characters"
      )
      .required("Brand name is required"),
  });

  return (
    <>
      <PageLayout>
        <div
          className="container"
          style={{
            backgroundColor: "",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="registration-container">
            <div className="d-flex">
              <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
                <div className="h1 fw-bold mb-0 almond-logo">
                  <img
                    className="img-fluid login-page-logo"
                    src="/images/almond-logo.png"
                  />
                </div>
              </div>
              <h2 className="fs-1 mb-3 pb-3 text-center"></h2>
            </div>
            <Formik
              initialValues={{
                brand: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form style={{width: '100%'}}>
                  <div className="form-group">
                    <label style={{ float: "left" }}>Brand Name</label>
                    <Field
                      type="text"
                      name="brand"
                      className="form-control"
                      placeholder="Enter Brand name"
                    />
                    <ErrorMessage
                      name="brand"
                      component="div"
                      className="error"
                    />
                  </div>

                  <div className="form-group text-center">
                    <button type="submit" className="btn-lg buttonlogin">
                      Create
                    </button>
                  </div>
                  <div style={{width: "100%", marginTop: '30px'}}>
                    {loading ? (
                      <Loader />
                    ) : brands?.length === 0 ? (
                      <div className="result-not-found">Data Not Found</div>
                    ) : (
                      <div>
                        <DataGrid
                          rows={brands}
                          columns={columns}
                          pageSize={rowsPerPage}
                          pagination
                          autoHeight
                          rowsPerPageOptions={[5, 10, 20, 25]} 
                          onPageSizeChange={(newPageSize) =>
                            setRowsPerPage(newPageSize)
                          }
                          getRowId={(row) => row._id}
                        />
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
        <style>
          {`
        .registration-container {
          width: 100%;
          padding: 50px;
          border-radius: 8px;
          background-color: #f7f7f7;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
            margin: auto;
          }
          .css-levciy-MuiTablePagination-displayedRows{
            margin: auto;
          }
          .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
            margin: 0
          }
        .container {
            padding: 20px;
          }
  
          .brand-items {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 15px;
          }
  
          .brand-item {
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 10px;
          }
  
          .brand-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
  
          .brand-name {
            margin-right: 10px;
            font-weight: bold;
          }
  
          .disable-button {
            padding: 5px 10px;
            background-color: #ea8523;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
          }
          @media only screen and (max-width: 768px) {
            .registration-container {
              padding: 20px; /* Adjust padding for smaller screens */
            }

            .brand-items {
              grid-template-columns: repeat(1, 1fr); /* Single column layout for brands */
            }

            /* Additional style adjustments for smaller screens */
            .brand-item {
              margin-bottom: 20px;
            }

            .brand-name {
              margin-right: 0;
            }

            .disable-button {
              margin-top: 10px;
            }

            .login-page-logo {
              max-width: 200px; /* Adjust logo size for smaller screens */
            }
          }
  
          .disable-button:disabled {
            background-color: #ccc;
            cursor: not-allowed;
          }

        .form-group {
        //   margin: 5px;
          text-align: center;
          width: 50%;
          margin: 10px auto;
        }
        .error {
          color: #ff0000;
          font-size: 14px;
        }
        .buttonlogin, .buttonlogin:hover{
          background:#ea8523;
          color:#fff;
          border:none;
          outline:none;
          font-size:22px;
          margin-top: 10px
        }

        .registration-container input{
          font-size:18px;
          width:100%;
        }
        .registration-container input:active,.registration-container input:hover, .registration-container input:focus, .registration-container input:focus-visible{
          border:none !important;
          outline:none !important;
        }
        .registration-container label{
          font-size:18px;
          font-weight:500;
        }
        .registration-container input::placeholder{
          font-size:16px;
  
        }
        .almond-logo{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-page-logo{
          max-width:300px !important;
        }
        .login-page-logo{
          width: 80%;
        }

        .disabledStyl{
          background-color:green;
          color:#fffffff;
        }
        

      `}
        </style>
      </PageLayout>
    </>
  );
};

export default CreateBrand;
