import React, { useState, useEffect } from "react";
import { useVendorId } from "../VendorIdContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import PageLayout from "../Layout/Layout";
import {
  FaUser,
  FaBuilding,
  FaIdCard,
  FaGlobe,
  FaPhone,
  FaEnvelope,
  FaLock,
} from "react-icons/fa";
import { baseUrl } from "../constant";

const RegisterForm = () => {
  // const { vendorId } = useVendorId();
  const [serviceOptions, setServiceOptions] = useState([]);
  const [serviceId, setServiceId] = useState("");
  const [vendorServiceId, setVendorServiceId] = useState("");
  const [globalIdMap, setGlobalIdMap] = useState({});
  const [registeredServices, setRegisteredServices] = useState([]);

  const navigate = useNavigate();
  const initialValues = {
    services: "",
  };

  const token = localStorage.getItem("token");
  const vendorId = localStorage.getItem("vendor_id");

  const handleServiceChange = (selectedServiceId, values) => {
    axios
      .post(
        `${baseUrl}/gratification/vendor-services`,
        {
          vendor_id: vendorId,
          service_id: selectedServiceId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        const vendor_service_id = response?.data?.data?._id;
        setVendorServiceId(vendor_service_id);
        console.log("Service ID:", vendor_service_id);

        // Assuming the registration is successful, update the registered service IDs
        setRegisteredServices((prevRegisteredServices) => [
          ...prevRegisteredServices,
          selectedServiceId,
        ]);

        const globalId = globalIdMap[selectedServiceId] || "";

        // Prepare data for the second API call
        const requestData = {
          vendor_id: vendorId,
          global_account_id: globalId,
          service_id: selectedServiceId,
          vendor_service_id: vendor_service_id,
        };

        console.log("requestData", requestData);

        // Second API call to register the client
        return axios.post(
          `${baseUrl}/gratification/vendor-services-account`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      })
      .then((response) => {
        console.log("Response:", response);
        if (response.status === 200) {
          Swal.fire({
            title: "Success",
            text: "Service Registered Successfully",
            icon: "success",
          });

          // document.querySelector("form").reset();
          // navigate("/ClientInformation");
        } else {
          throw new Error("Registration failed");
        }
      })
      .catch((error) => {
        console.error("Error making API calls:", error);

        const errorMessage = error?.response?.data?.message;
        const modifiedErrorMessage = errorMessage
          ? errorMessage.replace(/_/g, " ")
          : "An error occurred";

        Swal.fire({
          title: "Error",
          text: modifiedErrorMessage,
          icon: "error",
        });
      });
  };

  const validationSchema = Yup.object({
    services: Yup.string().required("Required"),
  });

  // const onSubmit = (values) => {
  //   console.log(values);
  // };

  useEffect(() => {
    axios
      .get(`${baseUrl}/gratification/get-all-services-without-pagination`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        const responseData = response?.data?.data;

        if (responseData && responseData.length > 0) {
          const serviceMap = {};
          console.log("heyyy", serviceMap);

          responseData.forEach((serviceObj) => {
            console.log("services", serviceObj);
            const serviceId = serviceObj.result?._id || null;
            const globalId = serviceObj._id;
            console.log("idsss", serviceObj._id);

            if (serviceId) {
              serviceMap[serviceId] = globalId; // Map serviceId to its corresponding globalId
            }
          });

          setGlobalIdMap(serviceMap);
          setServiceOptions(responseData);

          if (responseData[0]?.result?._id) {
            setServiceId(responseData[0]?.result?._id); 
          }
        } else {
          console.log("No service data found in the response.");
        }
      })
      .catch((error) => {
        console.error("Error fetching contact options:", error);
      });
  }, []);

  const onSubmit = (values) => {
    console.log("services", values);
    navigate("/ClientInformation");
  };

  return (
    <>
      <PageLayout>
        <div
          className="container"
          style={{
            backgroundColor: "",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="registration-container">
            <div className="d-flex">
              <div className="d-flex align-items-center justify-content-center mb-3 pb-1">
                <div className="h1 fw-bold mb-0 almond-logo">
                  <img
                    className="img-fluid login-page-logo"
                    src="/images/almond-logo.png"
                  />
                </div>
              </div>
              <h2 className="fs-1 mb-3 pb-3 text-center"></h2>
            </div>
            <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, handleChange }) => (
            <Form>
              <div className="form-group">
                <label style={{ float: "left" }}>Services</label>
                <select
                  as="select"
                  name="services"
                  className="form-control"
                  onChange={(e) => {
                    handleChange(e);
                    const selectedServiceId = e.target.value;
                    handleServiceChange(selectedServiceId, values);
                    console.log("value", selectedServiceId);
                  }}
                  value={values.services}
                >
                  <option value="">*Select service</option>
                  {serviceOptions.length > 0 &&
                    serviceOptions.map((option) => {
                      const serviceNameWithSpaces = option?.result?.name.replace(/_/g, " ");
                      const serviceProviders = option?.result?.service_provider.replace(
                        /_/g,
                        " "
                      );
                      const serviceId = option?.result?._id;
  
                      const isServiceRegistered =
                        registeredServices.includes(serviceId);
  
                      return (
                        <option
                          key={serviceId}
                          value={serviceId}
                          disabled={isServiceRegistered} 
                        >
                          {serviceNameWithSpaces} - ({serviceProviders})
                        </option>
                      );
                    })}
                </select>
                <ErrorMessage
                  name="services"
                  component="div"
                  className="error"
                />
              </div>
              <div className="form-group text-center">
                <button type="submit" className="btn-lg  buttonlogin">
                  Submit
                </button>
              </div>
              <br />
              <div className="selected-services">
                <h4>Selected Services</h4>
                {registeredServices.length > 0 ? (
                  <ul>
                    {registeredServices.map((serviceId) => {
                      const selectedService = serviceOptions.find(
                        (option) => option?.result?._id === serviceId
                      );

                      if (selectedService) {
                        const serviceName = selectedService?.result?.name.replace(/_/g, " ");
                        return (
                          <li key={serviceId}>
                            {serviceName}
                          </li>
                        );
                      }

                      return null;
                    })}
                  </ul>
                ) : (
                  <p>No services selected</p>
                )}
              </div>
            </Form>
          )}
        </Formik>
          </div>
        </div>
        <style>
          {`
        .registration-container {
          width: 70%;
          padding: 50px;
          border-radius: 8px;
          background-color: #f7f7f7;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .form-group {
          margin: 10px;
          text-align: center;
          width: 100%;
        }
        .error {
          color: #ff0000;
          font-size: 14px;
        }
        .buttonlogin, .buttonlogin:hover{
          background:#1e2c42;
          color:#fff;
          border:none;
          outline:none;
          font-size:22px;
          margin-top: 20px
        }

        .registration-container input{
          font-size:18px;
          width:100%;
        }
        .registration-container input:active,.registration-container input:hover, .registration-container input:focus, .registration-container input:focus-visible{
          border:none !important;
          outline:none !important;
        }
        .registration-container label{
          font-size:18px;
          font-weight:500;
        }
        .registration-container input::placeholder{
          font-size:16px;
  
        }
        .almond-logo{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-page-logo{
          max-width:300px !important;
        }
        .login-page-logo{
          width: 80%;
        }
        

      `}
        </style>
      </PageLayout>
    </>
  );
};

export default RegisterForm;
