import React from "react";
import PageLayout from "../Layout/Layout";

const Setting = () => {
  return (
    <>
      <PageLayout>
        <div
          className="ms-4"
          style={{ height: 400, width: "95%", marginTop: "10px" }}
        >
          <h3>Setting</h3>
        </div>
      </PageLayout>
    </>
  );
};

export default Setting;
