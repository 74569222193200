import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import { baseUrl } from "../constant";
import Loader from "../../src/components/loader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

const CouponCard = () => {
  const [coupons, setCoupons] = useState([])
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState(0);
    const [page, setPage] = useState(1);
    const [size, setSize] = useState(10); 
    
    
      const columns = [
        { field: "id", headerName: "id", width: 150 },
        {
          field: "service_type",
          headerName: "Service Type",
          width: 150,
        },
        {
          field: "type",
          headerName: "Type",
          width: 150,
        },
        {
          field: "vendor_id",
          headerName: "Client ID",
          width: 150,
        },
        {
          field: "status",
          headerName: "Status",
          width: 150,
        },
        {
          field: "credits",
          headerName: "Credits",
          width: 150,
        },
        {
          field: "vault_balance",
          headerName: "Vault Balance",
          width: 150,
        },
        {
          field: "is_credit_used",
          headerName: "Is Credit Used",
          width: 150,
        },
        {
          field: "transaction_id",
          headerName: "Transaction ID",
          width: 200,
        },
        {
          field: "vendor_transaction_id",
          headerName: "Client Transaction ID",
          width: 200,
        },
        {
          field: "createdAt",
          headerName: "Created At",
          width: 200,
        },
        {
          field: "updatedAt",
          headerName: "Updated At",
          width: 200,
        },
      ];
    
      const getCoupons = async () => {
        try {
          const tokenFromLocalStorage = localStorage.getItem("token");
          const configHeaders = {
            headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
          };
    
          
          const requestBody = {
            vendor_id: localStorage.getItem("vendor_id"),
            type: "coupon_card",
          };
    
          const res = await axios.post(
            `${baseUrl}/gratification/get-all-services-by-type?page=${page}&size=${size}`, 
            requestBody,
            configHeaders
          );
          console.log("🚀 ~ file: vendors.js:72 ~ getCoupons ~ res:", res)
          
          const CouponsData = await res?.data?.data;
          console.log("🚀 ~ file: vendors.js:67 ~ getCoupons ~ CouponsData:", CouponsData)
          let result = [];
          CouponsData?.data?.map((item, i) => {
            result.push({
              id: (page - 1) * size + i + 1,
          service_type: item.service_type,
          type: item.type,
          vendor_id: item.vendor_id,
          status: item.status,
          credits: item.credits,
          vault_balance: item.vault_balance,
          is_credit_used: item.is_credit_used,
          transaction_id: item.transaction_id,
          vendor_transaction_id: item.vendor_transaction_id,
          createdAt: new Date(item.createdAt).toLocaleString('en-IN'),
          updatedAt: new Date(item.updatedAt).toLocaleString('en-IN')
            });
          });
          setCoupons(result);
          setPagination(CouponsData?.totaCount);
          setLoading(false);
          console.log("Pagination: ", pagination);
          console.log(res);
        } catch (error) {
          console.log(error);
        }
      };
    
      useEffect(() => {
        getCoupons();
        console.log("Pagination", pagination);
      }, [page, size]);
      
    
      return (
        <>
          <PageLayout>
            <Card className="mt-4" style={{ width: "100%" }}>
              <CardContent>
                <Typography variant="h5" component="div" style={{color: "#95500c", fontWeight: 600, marginBottom: 10}}>
                  Clients
                </Typography>
                <div style={{ height: "auto" }}>
                  {loading ? (
                    <Loader />
                  ) : coupons?.length === 0 ? (
                    <div className="result-not-found">Data Not Found</div>
                  ) : (
                    <DataGrid
                      rows={coupons}
                      columns={columns}
                      rowsPerPageOptions={[5, 10, 20]}
                      pagination
                      pageSize={size}
                      rowCount={pagination}
                      paginationMode="server"
                      onPageChange={(newPage) => {
                        setPage(newPage + 1);
                        console.log(newPage);
                      }}
                      onPageSizeChange={(newPageSize) => setSize(newPageSize)}
                      autoHeight
                      {...coupons}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
            <style>{`
    
          .react-toggle--checked .react-toggle-track {
            background-color: grey !important;
          }

          .css-rtrcn9-MuiTablePagination-root .MuiTablePagination-selectLabel{
            margin: auto;
          }
          .css-levciy-MuiTablePagination-displayedRows{
            margin: auto;
          }
          .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
            margin: 0
          }
          
          .css-t89xny-MuiDataGrid-columnHeaderTitle {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            font-weight: var(--unstable_DataGrid-headWeight);
            font-size: 15px;
            font-weight: bold;
          }
      `}</style>
          </PageLayout>
        </>
      );
};

export default CouponCard;
