import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import PageLayout from "../Layout/Layout";
import Swal from "sweetalert2";
import { baseUrl } from "../constant";

export default function CreateAdmin() {
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const tokenFromLocalStorage = localStorage.getItem("token");
        const configHeaders = {
          headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
        };
        const response = await axios.get(
          `${baseUrl}/gratification/get-all-roles`,
          configHeaders
        );
        if (response.data && response.data.data) {
          console.log("Roles data#%#%##", response.data.data);

          if (Array.isArray(response.data.data)) {
            // console.log("Roles:", response.data.data.role);
            setRoles(response.data.data || []);
          } else {
            console.error("Roles data is not an array:", response.data.data);
          }
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchRoles();
  }, []);

  const validationSchema = Yup.object({
    role: Yup.string().required("Role is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
      password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
        "Password must be at least 8 characters long, start with a capital letter, and contain at least one special character"
      )
      .required("Required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    try {
      const tokenFromLocalStorage = localStorage.getItem("token");
      const configHeaders = {
        headers: { Authorization: `Bearer ${tokenFromLocalStorage}` },
      };

      const selectedRole = roles.find((role) => role._id === values.role);

      if (!selectedRole) {
        console.error("Invalid role selected");
        return;
      }

      const createAdminResponse = await axios.post(
        `${baseUrl}/gratification/create-admin`,
        {
          role_id: selectedRole._id,
          email: values.email,
          password: values.password,
        },
        configHeaders
      );

      console.log("Create Admin Response:", createAdminResponse.data);

      Swal.fire({
        icon: "success",
        title: "Admin created successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      resetForm();
    } catch (error) {
      console.error("Error creating admin:", error);

      Swal.fire({
        icon: "error",
        title: "Error creating admin",
        text: error?.response?.data?.message || "An unexpected error occurred.",
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      role: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit,
  });

  return (
    <PageLayout>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <h1 className="mb-4 mt-4">Create Admin</h1>

            <form onSubmit={formik.handleSubmit}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Email address"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`form-control ${
                    formik.touched.email && formik.errors.email
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="invalid-feedback">{formik.errors.email}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password:
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter Your Password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  className={`form-control ${
                    formik.touched.password && formik.errors.password
                      ? "is-invalid"
                      : ""
                  }`}
                />
                {formik.touched.password && formik.errors.password ? (
                  <div className="invalid-feedback">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>

              <div className="mb-3">
                <label htmlFor="role" className="form-label">
                  Select Role:
                </label>
                <select
                  id="role"
                  name="role"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                  className={`form-select ${
                    formik.touched.role && formik.errors.role
                      ? "is-invalid"
                      : ""
                  }`}
                >
                  <option value="" label="Select a role" />
                  {roles.map((role) => {
                    return (
                      <option
                        key={role.id}
                        value={role._id}
                        label={role.role}
                      />
                    );
                  })}
                </select>
                {formik.touched.role && formik.errors.role ? (
                  <div className="invalid-feedback">{formik.errors.role}</div>
                ) : null}
              </div>

              <div className="mb-3">
                <button type="submit" className="mt-3 btn-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <style>
          {`
                .form-label {
                    color: #ea8523;
                }
                .btn-btn{
                    display: flex;
                    justify-content: center;
                    margin: auto;
                    background-color: #ea8523;
                    padding:  5px 10px;
                    border-radius: 5px;
                    border: none;   
                }
                `}
        </style>
      </div>
    </PageLayout>
  );
}
