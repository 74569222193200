import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import SideBar from "./SideBar";
import Modal from "./TokenGenerationDialog"; 
import "../index.css";

const Navbar = () => {
  const [btn, setBtn] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [tokenDialogOpen, setTokenDialogOpen] = useState(false);
  const [sideBar, setSideBar] = useState("");

  const codeToCopy = "Your code to be copied here";


  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setBtn(true);
    } else {
      navigate("/");
    }
  });

  const handleLogout = () => {
    localStorage.clear();
  };

  const handleAvtar = () => {
    setModalOpen(!ModalOpen);
  };

  const divRef = useRef(null);
  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      setModalOpen(false);
    }
  };

  useEffect(() => {
    document
      .querySelector(".mc-main-container")
      .addEventListener("mousedown", handleClickOutside);
    document
      .querySelector(".mc-sidebar")
      .addEventListener("mousedown", handleClickOutside);
  }, []);

  const toggleSidebar = () => {
    const screenWidth = window.screen.width;
    const sidebar = document.querySelector(".mc-sidebar");
  
    if (screenWidth > 481) {
      setIsClicked(!isClicked);
      if (isClicked) {
        sidebar.style.display = "none";
      } else {
        sidebar.style.display = "block";
      }
    } else {
      if (sidebar.style.display === "none" || sidebar.style.display === "") {
        sidebar.style.display = "block"; // Ensure sidebar is visible for larger screens if it was hidden
      } else {
        sidebar.style.display = ""; // Allow the default behavior (CSS rules) for larger screens
      }
    }
  };
  
  

  return (
    <Box sx={{ marginBottom: "65px" }}>
      <AppBar position="fixed">
        <Toolbar>
          <div className="nav-menu-icon">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
            >
              <GridMenuIcon onClick={toggleSidebar} />
            </IconButton>
          </div>
          <div className="brand_name">
            <img
              src="/images/almond-logo.png"
              alt="logo-image "
              className="mc-logo"
            />
          </div>
          {/* <div className="ml-auto">
            <button  className="btn btn-primary" onClick={() => setTokenDialogOpen(true)}>Genrate Token</button>
          </div> */}

          {btn ? (
            <>
              <Button
                color="inherit"
                variant="contained"
                sx={{
                  marginLeft: "auto",
                  marginRight: "10px",
                  width: "55px",
                  minWidth: "55px",
                  height: "55px",
                  borderRadius: "50px",
                  padding: "0px",
                  background: "white",
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
                className="btn-login"
                onClick={handleAvtar}
              >
                {/* LogOut */}
                <img
                  src="/images/avtar.png"
                  alt="logo-image "
                  className="mc-avtar"
                />
              </Button>
            </>
          ) : (
            <Button
              color="inherit"
              variant="contained"
              sx={{
                marginLeft: "auto",
                marginRight: "10px",
                width: "55px",
                minWidth: "55px",
                height: "55px",
                borderRadius: "50px",
                padding: "0px",
                background: "white",
                color: "black",
                fontWeight: "bold",
                fontSize: "18px",
              }}
              className="btn-login"
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <div
        className={`${ModalOpen ? "modalActive" : ""} logout_box`}
        ref={divRef}
      >
        <a href="/" onClick={handleLogout}>
          Logout
        </a>
      </div>
      <Modal open={tokenDialogOpen} onClose={() => setTokenDialogOpen(false)} codeToCopy={codeToCopy} />

      <style>
        {
          `
          .logout_box {
            position: fixed;
            // top: 0;
            right: 20px;
          }
          `
        }
      </style>
    </Box>
  );
};

export default Navbar;
