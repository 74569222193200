import { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import axios from "axios";
import { shippingUrlDev, shippingUrlProd } from "../constant";
import Swal from "sweetalert2";

export default function UpdateAwb() {

    const [form, setForm] = useState({ vendor: "DELHIVERY" });

    const [isPending, setIsPending] = useState(false);


    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        try {
            e.preventDefault();

            if (!isPending) {
                setIsPending(true);


                // generateToken 

                const { data } = await axios.post(`${shippingUrlDev}/api/auth/login`,
                    {
                        "email": "paraskumar2410@gmail.com",
                        "password": "Paras&Kumar12"
                    }
                );

                if (!data || !data.token) {

                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `No token found. Please try again`
                    });

                    return;

                }

                // Assuming `form` contains the data to be sent
                const response = await axios.post(`${shippingUrlDev}/api/track/update-awb-number`, form, {
                    headers: {
                        "Authorization": `Bearer ${data.token}`
                    }
                });

                if (response.status === 200) {
                    // Success alert using sweetalert2
                    Swal.fire({
                        icon: 'success',
                        title: 'AWB updated successfully!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                }

                console.log(response);
            }
        } catch (err) {
            console.log(err.message);

            // Error alert using sweetalert2
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err.response.data.message
            });
        } finally {
            setIsPending(false); // Reset isPending flag regardless of success or failure
        }
    };

    useEffect(() => {
        console.log({ form });
    }, [form]);

    return <>
        <PageLayout>
            <div className="mc-hero-container">

                <h2>Update Awb Number</h2>

                <form onSubmit={handleSubmit}>
                    <input type="text" name="order_id"
                        className="mc-input"
                        placeholder="Enter Order Id"
                        onChange={handleChange}
                        pattern="[0-9]{2,16}"
                        onInvalid={(e) => e.target.setCustomValidity('Order Id should contain numbers only.')}
                        required />
                    <input
                        type="tel"
                        name="awb"
                        className="mc-input"
                        placeholder="Enter Awb Number"
                        onChange={handleChange}
                        required
                        pattern="[0-9]{12,16}"
                        onInvalid={(e) => e.target.setCustomValidity('Please enter a valid AWB number (12-16 digits).')}
                    />
                    <select onChange={handleChange} name="vendor" required defaultValue={'DELHIVERY'}>
                        <option value={'DELHIVERY'}>DELHIVERY</option>
                        <option value={'XPRESSBEES'}>XPRESSBEES</option>
                    </select>

                    <button type="submit">
                        Update
                    </button>
                </form>

            </div>
        </PageLayout>
    </>
}