import React, { useEffect, useState } from "react";
import PageLayout from "../Layout/Layout";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../src/components/loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseUrl } from "../constant";

export default function Main() {
  const navigate = useNavigate();
  const [merchantTransactions, setMerchantTransactions] = useState([]);
  const [serviceTransactions, setServiceTransactions] = useState([]);
  const [selectedTab, setSelectedTab] = useState("merchant");
  const [isLoading, setIsLoading] = useState(true);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    const handleBackButton = () => {
      window.history.pushState(null, null, window.location.pathname);
      window.history.forward();
      window.location.replace("/dashboard");
    };
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/");
    } else {
      // fetchInitialTransactions();
    }
  }, []);

  const isAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
  };

  const vendorId = localStorage.getItem("vendor_id");
  const role = localStorage.getItem("role");

  const fetchInitialTransactions = () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const merchantApiUrl = `${baseUrl}/gratification/allMerchantTranscations`;
    const merchantApiUrlwithVendor = `${baseUrl}/gratification/allMerchantTranscations?vendor_id=${vendorId}`;
    const serviceApiUrl = `${baseUrl}/gratification/serviceTypeTranscations`;
    const serviceApiUrlwithVendor = `${baseUrl}/gratification/serviceTypeTranscations?vendor_id=${vendorId}`;

    setIsLoading(true);

    if (role === "client") {
      axios
        .get(
          selectedTab === "merchant"
            ? merchantApiUrlwithVendor
            : serviceApiUrlwithVendor,
          {
            headers: headers,
          }
        )
        .then((response) => {
          setIsLoading(false);

          if (selectedTab === "merchant" && Array.isArray(response.data.data)) {
            setMerchantTransactions(response.data.data);
          } else if (
            selectedTab === "service" &&
            Array.isArray(response.data.data)
          ) {
            setServiceTransactions(response.data.data);
          } else {
            console.error("API response does not contain an array.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching initial data:", error);
        });
    } else {
      axios
        .get(selectedTab === "merchant" ? merchantApiUrl : serviceApiUrl, {
          headers: headers,
        })
        .then((response) => {
          setIsLoading(false);

          if (selectedTab === "merchant" && Array.isArray(response.data.data)) {
            setMerchantTransactions(response.data.data);
          } else if (
            selectedTab === "service" &&
            Array.isArray(response.data.data)
          ) {
            setServiceTransactions(response.data.data);
          } else {
            console.error("API response does not contain an array.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching initial data:", error);
        });
    }
  };

  useEffect(() => {

    const interval = setTimeout(() => {

      if (startDate !== null && endDate !== null) {

        fetchTransactionsWithDateFilter();
      } else {

        if (selectedTab && !startDate && !endDate) {

          const interval2 = setTimeout(() => {
            fetchInitialTransactions();
          }, 10);

          return () => clearInterval(interval2);

        }
      }
    }, 10);

    return () => clearInterval(interval);

  }, [selectedTab, startDate, endDate]);

  const fetchTransactionsWithDateFilter = () => {
    const token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    const formattedStartDate = new Date(
      startDate.getTime() - startDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    const formattedEndDate = new Date(
      endDate.getTime() - endDate.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];

    const merchantApiUrlwithVendor = `${baseUrl}/gratification/allMerchantTranscations?start_date=${formattedStartDate}&end_date=${formattedEndDate}&vendor_id=${vendorId}`;
    const merchantApiUrl = `${baseUrl}/gratification/allMerchantTranscations?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
    const serviceApiUrlwithVendor = `${baseUrl}/gratification/serviceTypeTranscations?start_date=${formattedStartDate}&end_date=${formattedEndDate}&vendor_id=${vendorId}`;
    const serviceApiUrl = `${baseUrl}/gratification/serviceTypeTranscations?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;

    setIsLoading(true);

    if (role === "client") {
      const apiUrlwithVendor =
        selectedTab === "merchant"
          ? merchantApiUrlwithVendor
          : serviceApiUrlwithVendor;

      axios
        .get(apiUrlwithVendor, {
          headers: headers,
        })
        .then((response) => {
          setIsLoading(false);

          if (selectedTab === "merchant" && Array.isArray(response.data.data)) {
            setMerchantTransactions(response.data.data);
          } else if (
            selectedTab === "service" &&
            Array.isArray(response.data.data)
          ) {
            setServiceTransactions(response.data.data);
          } else {
            console.error("API response does not contain an array.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(
            "Error fetching merchant data with date filter:",
            error
          );
        });
    } else {
      const apiUrl =
        selectedTab === "merchant" ? merchantApiUrl : serviceApiUrl;

      axios
        .get(apiUrl, {
          headers: headers,
        })
        .then((response) => {
          setIsLoading(false);

          if (selectedTab === "merchant" && Array.isArray(response.data.data)) {
            setMerchantTransactions(response.data.data);
          } else if (
            selectedTab === "service" &&
            Array.isArray(response.data.data)
          ) {
            setServiceTransactions(response.data.data);
          } else {
            console.error("API response does not contain an array.");
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.error("Error fetching data with date filter:", error);
        });
    }
  };

  useEffect(() => {
    console.log(serviceTransactions);
  }, [serviceTransactions]);

  useEffect(() => {
    console.log({ merchantTransactions });
  }, [merchantTransactions]);

  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (endDate && date > endDate) {
      setEndDate(null); // Reset end date if it's before the new start date
    }
  };

  const handleEndDateChange = (date) => {
    if (startDate && date < startDate) {
      return; // Prevent selecting end date before start date
    }
    setEndDate(date);
  };

  return (
    <PageLayout>
      <div className="container">
        <div className="row">
          <div className="col-12 mt-1">
            <div className="btn-group" role="group">
              <button
                onClick={() => setSelectedTab("merchant")}
                className={`btn ${selectedTab === "merchant" ? "btn-primary" : "btn-secondary"
                  } custom-button`}
              >
                Merchant Transactions
              </button>
              <button
                onClick={() => setSelectedTab("service")}
                className={`btn ${selectedTab === "service" ? "btn-primary" : "btn-secondary"
                  } custom-button`}
              >
                Service Transactions
              </button>
              <div className="date-filter d-flex align-items-center">
                <div className="mr-2">
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    placeholderText="Start Date"
                    className="form-control"
                  />
                </div>
                <div className="mr-2">
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    minDate={startDate} // Set minimum date for the end date picker
                    placeholderText="End Date"
                    className="form-control"
                  />
                </div>
                <button className="btn  btn-danger" onClick={handleClearDates}>
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? (
          <Loader />
        ) : (
          <div className="row card-container">
            {selectedTab === "merchant"
              ? merchantTransactions.map((transaction, index) => (
                <div className="col-md-6" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <div className="icon"></div>
                      <div className="content">
                        <div className="label">Service Type:</div>
                        <div className="value">{transaction.serviceType}</div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Total Count:</div>
                        <div className="value">
                          {transaction.transactions_total_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Failed Count:</div>
                        <div className="value">
                          {transaction.transactions_failed_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Pending Count:</div>
                        <div className="value">
                          {transaction.transactions_pending_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Success Count:</div>
                        <div className="value">
                          {transaction.transactions_success_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Total Amount:</div>
                        <div className="value">
                          {transaction.transactions_total_amount}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Failed Amount:</div>
                        <div className="value">
                          {transaction.transactions_failed_amount}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Pending Amount:</div>
                        <div className="value">
                          {transaction.transactions_pending_amount}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Success Amount:</div>
                        <div className="value">
                          {transaction.transactions_success_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
              : serviceTransactions.map((transaction, index) => (
                <div className="col-md-6" key={index}>
                  <div className="card">
                    <div className="card-body">
                      <div className="icon"></div>
                      <div className="content">
                        <div className="label">Service Type:</div>
                        <div className="value">{transaction.serviceType}</div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Total Count:</div>
                        <div className="value">
                          {transaction.transactions_total_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Failed Count:</div>
                        <div className="value">
                          {transaction.transactions_failed_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Pending Count:</div>
                        <div className="value">
                          {transaction.transactions_pending_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Success Count:</div>
                        <div className="value">
                          {transaction.transactions_success_count}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Total Amount:</div>
                        <div className="value">
                          {transaction.transactions_total_amount}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Failed Amount:</div>
                        <div className="value">
                          {transaction.transactions_failed_amount}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Pending Amount:</div>
                        <div className="value">
                          {transaction.transactions_pending_amount}
                        </div>
                      </div>
                      <div className="content">
                        <div className="label">Transactions Success Amount:</div>
                        <div className="value">
                          {transaction.transactions_success_amount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      <style>
        {`
        .btn_clear{
          // background-color:#95500c;
          // color: #FFFFFF;
          background-color: darkorange;
          box-shadow: 0px 0px 0px 2px lightblue;
          border-radius: 10px !important;
        }
        .btn_clear:hover{
          background-color:#95500c;
          color: #FFFFFF;
        }
        // .merchant_transaction{
        //   // background-color:#95500c;
        //   background-color: darkorange;
        //   box-shadow: 0px 0px 0px 2px lightblue;
        //   border-radius: 10px !important;
        //   font-family:"poppins"
        //   color: #FFFFFF;
        //   font-weight: bold;
        // }
        // .merchant_transaction:hover{
        //   // background-color:#95500c;
        //   background-color: darkorange;
        //   box-shadow: 0px 0px 0px 2px lightblue;
        //   border-radius: 10px !important;
        // }

        // .btn-secondary{
        //   // background-color: darkorange;
        //   box-shadow: 0px 0px 0px 2px lightblue;
        //   border-radius: 10px !important;
        //   font-family:"poppins"
        //   color: black;
        //   font-weight: bold;
        //   --bs-btn-color: #000;
        // }
        // .btn-secondary:active{
        //   // background-color: darkorange;
        //   box-shadow: 0px 0px 0px 2px lightblue;
        //   border-radius: 10px !important;
        //   font-family:"poppins"
        //   color: white;
        //   font-weight: bold;
        // }
          .card-container {
            display: flex;
            flex-wrap: wrap;
            margin: -10px;
          }

          .btn-group {
            display: flex;
            margin-bottom: 15px;
          }

          .btn {
            border-radius: 0;
            flex: 1;
            margin: 0 5px;
          }

          .card {
            background: #fff;
            border: 1px solid #e4e7ea;
            border-radius: 3px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
            margin: 10px;
            padding: 10px;
            width: 100%;
          }

          .card-body {
            // padding: 15px;
          }

          .icon {
            text-align: center;
          }

          .icon svg {
            margin: 10px;
          }

          .content {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
          }

          .label {
            font-weight: bold;
            color: #333;
          }

          .value {
            font-weight: 600;
          }

          .date-filter {
            display: flex;
            align-items: center;
          }

          .date-filter > * {
            margin-right: 10px;
          }
        `}
      </style>
    </PageLayout>
  );
}
