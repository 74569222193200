import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { CopyToClipboard } from "react-copy-to-clipboard"; // Import the CopyToClipboard component

const TokenGenerationDialog = ({ open, onClose, codeToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Generate Token</DialogTitle>
      <DialogContent>
        {/* Add content for token generation here */}
        <div>
          <pre>{codeToCopy}</pre>
          <CopyToClipboard text={codeToCopy} onCopy={handleCopy}>
            <Button variant="contained" color="primary">
              {isCopied ? "Copied!" : "Copy Code"}
            </Button>
          </CopyToClipboard>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TokenGenerationDialog;
