import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import SideBar from '../components/SideBar';
import CouponCard from "../components/CouponCard" // Import your CouponCard component // Import the CSS file for styling
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import PageLayout from '../Layout/Layout';
import axios from "axios";
import { baseUrl } from "../constant";

export default function CouponData() {
    const [coupon, setCoupon] = useState(null)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.state && location.state.rowData) {
            setCoupon(location.state.rowData);
            console.log("hn", location.state.rowData)
        } else {
            console.log("coupon")
        }
    }, [location.state]);

    return (
        <PageLayout>
            <div>
                <button type='button' style={{margin:"0px 0px 0px 65px"}} className='btn btn-warning mt-3' onClick={() => {
                    navigate(-1)
                }}>Go Back</button>
                <div className="mc-main-container coupon_container d-flex justify-content-start">
                    <CouponCard coupon={coupon} />
                </div>
            </div>
        </PageLayout>
    );
}
