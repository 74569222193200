import React from 'react'

const UsersTable = (props) => {
  return (
    <>
    <h2 style={{marginTop: '30px'}}>{props.title}</h2>
    </>
  )
}

export default UsersTable;